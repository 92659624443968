import React, { Fragment } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Btn } from "../../../../AbstractElement";
import { useAdminAuthContext } from "../../../../Context/Auth/AdminAuthContext";

export const Layout = () => {
  const navigate = useNavigate();
  const { handleAdminSignOut } = useAdminAuthContext();

  const handleSignOut = (navigate) => {
    handleAdminSignOut(navigate);
  };

  return (
    <Fragment>
      <div className="d-flex gap-3 justify-content-end">
        <Btn color="light" className="border border-1" onClick={()=>handleSignOut(navigate)}>
          Sign Out
        </Btn>
      </div>
      <Outlet />
    </Fragment>
  );
};
