import React, { useEffect, useRef } from "react";

const OTPInput = ({ val, setVal, isError, setIsError }) => {
  const inputRefs = useRef([]);

  useEffect(() => {
    inputRefs.current[0].focus();
  }, []);

  useEffect(() => {
    if (isError) {
      const timer = setTimeout(() => {
        setIsError(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [isError, setIsError]);

  const handleBoxChange = (e, index) => {
    if (e.length > 1) return;

    const temp = [...val];
    temp[index] = e;
    setVal(temp);
    if (isError) {
      setIsError(false);
    }
  };

  const handleChange = (e, index) => {
    const { value } = e.target;

    // Handle value change only if a single character is entered
    if (value.length === 1) {
      handleBoxChange(value, index);

      // Move to the next field if it's not the last input
      if (index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    const temp = [...val];

    if (e.key === 'Backspace') {
      if (val[index]) {
        // Clear current value when backspace is pressed
        temp[index] = '';
        setVal(temp);
      } else if (index > 0) {
        // Focus the previous field if the current one is empty
        inputRefs.current[index - 1]?.focus();
      }
    }
    // Left Arrow Key: Move to the previous input field
    else if (e.key === 'ArrowLeft' && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
    // Right Arrow Key: Move to the next input field
    else if (e.key === 'ArrowRight' && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handlePaste = (e) => {
    const pasteData = e.clipboardData.getData('text').slice(0, 6);
    const newVal = pasteData.split('');

    if (newVal.length === 6) {
      setVal(newVal);
      setIsError(false);
      newVal.forEach((char, index) => {
        inputRefs.current[index]?.setAttribute('value', char);
      });
    }

    // Focus the last field after pasting all characters
    const filledFields = Math.min(newVal.length, inputRefs.current.length - 1);
    inputRefs.current[filledFields]?.focus();
  };

  return (
    <div className="otp-code">
      {[...Array(6)].map((_, index) => (
        <input
          key={index}
          value={val[index] || ""}
          type="number"
          pattern="[0-9]*" 
          maxLength={1}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          onPaste={handlePaste}
          className={`otp-field ${isError ? "invalid-field" : ""}`}
          ref={(el) => (inputRefs.current[index] = el)}
        />
      ))}
    </div>
  );
};

export default OTPInput;
