import { AlternateEmail, Clear, InfoOutlined } from "@mui/icons-material";
import { OutlinedInput, Tooltip } from "@mui/material";
import React, { useEffect } from "react";
import { Form } from "reactstrap";

import { Btn, Image } from "../../../../AbstractElement";
import { getParams, useSetUrlParams } from "../../../../Api/UrlParams";
import { useCreatorContext } from "../../../../Context/Creator/CreatorContext";
import { dynamicImage } from "../../../../Helper";

const CreatorAiFilter = ({ setParams, loading, setUrlParamsProcessed, setPage, setLimit }) => {
  const { aiSearch, setAiSearch } = useCreatorContext();
  const setParam = useSetUrlParams();
  const getParam = getParams();

  useEffect(() => {
    if (getParam.brand) {
      setAiSearch(getParam.brand);
    } else {
      setAiSearch("");
    }
  }, [getParam.brand, setAiSearch]);

  const submitSearch = async (e) => {
    e.preventDefault();
    setUrlParamsProcessed(true);
    setParams((prevParams) => ({ ...prevParams, brand: aiSearch }));
    setPage(1);
    setLimit(25);
    setParam({ brand: aiSearch });
  };

  return (
    <div className="mb-4">
      <div className="ai-search-panel mb-1">
        <h5>AI-Powered search</h5>
        <Tooltip title="Try our AI recommendation model to find creators best suited for your brand" placement="top">
          <span><InfoOutlined className="info-icon" fontSize="5px" /></span>
        </Tooltip>
        <span className="taglNews">New</span>
      </div>
      <div className="w-100">
        <div className="top-filter-panel w-100 h-100 mb-4">
          <Form className="form-search-box h-100 w-100" noValidate onSubmit={submitSearch}>
            <OutlinedInput disabled={false} startAdornment={<AlternateEmail className="info-icon" position="start" />} endAdornment={aiSearch.length > 0 && <Clear className="cursor-pointer" onClick={() => setAiSearch("")} />} className="w-100 me-0 ai-input" placeholder="Please enter your active instagram handle e.g. yourbrand ..." value={aiSearch} onChange={(e) => setAiSearch(e.target.value)} />
            <Btn className="btn-theme search-btn h-100" type="submit" disabled={!aiSearch.length || loading}>
              <Image src={dynamicImage("svg/search.svg")} className="search-icon" alt="Settings Icon" /> Search
            </Btn>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default CreatorAiFilter;
