import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";

import { dynamicImage } from "../../Helper";
import { ROUTES } from "../../Routes/Routes";
import { Image } from "../../AbstractElement";

const CommonBreadcrumb = ({ title, subTitle }) => {
  return (
    <Fragment>
      <h5 className="title text-center">{title}</h5>
      {/* <Breadcrumb className="sb-space">
        <BreadcrumbItem>
          <Link to={ROUTES.Home}>
            <Image src={dynamicImage("svg/home-2.svg")} /> Home
          </Link>
        </BreadcrumbItem>
        <BreadcrumbItem active> {subTitle ? subTitle : title}</BreadcrumbItem>
      </Breadcrumb> */}
    </Fragment>
  );
};

export default CommonBreadcrumb;
