import { useMutation } from "@tanstack/react-query";

import { axiosInstance, useErrorMessageHandler } from "./middleware";

export const useDeleteApi = ({ url, options, onSuccess }) => {
  const { handleError } = useErrorMessageHandler();

  const mutation = useMutation({
    mutationFn: async (data) => {
      try {
        // The payload (data) will be passed into axios.delete as a body
        const response = await axiosInstance.delete(url, {
          data, // Send payload as part of the delete request body
        });
        return response.data;
      } catch (error) {
        handleError(error);
      }
    },
    onSuccess: (data) => {
      if (onSuccess) {
        onSuccess(data); // Execute success callback if provided
      }
    },
    ...options, // Include options like onSuccess, onError
  });

  return mutation;
};
