import { ArrowOutward, Clear, Instagram, YouTube } from "@mui/icons-material";
import { OutlinedInput } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { FormGroup } from "reactstrap";

import { Btn, Image } from "../../../../AbstractElement";
import { useCreatorFavoriteContext } from "../../../../Context/Creator/CreatorFavoriteContext";
import { dynamicImage, formatDate } from "../../../../Helper";
import { useAutoFocus } from "../../../../Hooks/useAutoFocus";
import { useCreatorContext } from "../../../../Context/Creator/CreatorContext";

export const CreatorSubLists = () => {
  const { exportFavoriteListCsv, toggleCreatorListModal, singleDelete, handleInfluencerDeleteClick, toggleDeleteList, handleDeleteClick, selectAll, clearSubListSelection, handleSelectAllChange, handleIndividualCheckboxChange, setSearchSubList, searchSubList, filteredSubList, setSubList, subList, selectedSubLists, setSelectedSubLists } = useCreatorFavoriteContext();
  const { handleUrl } = useCreatorContext();

  const isListSelected = useMemo(() => selectedSubLists.length > 0, [selectedSubLists.length]);
  const inputRef = useAutoFocus(subList);

  const handleClearSearch = useCallback(() => setSearchSubList(""), [setSearchSubList]);

  const handleSearchChange = useCallback(
    (e) => {
      setSearchSubList(e.target.value);
      setSelectedSubLists([]);
    },
    [setSearchSubList, setSelectedSubLists]
  );

  const handleBack = useCallback(() => {
    setSubList(null);
    clearSubListSelection();
    setSearchSubList("");
  }, [setSubList, clearSubListSelection, setSearchSubList]);

  const renderUserRow = useCallback(
    (lists) => (
      <li className={`user-row ${selectedSubLists.includes(lists.id) ? "active" : ""}`} key={lists.id}>
        <div className="user-list">
          <input type="checkbox" className="check-box" checked={selectedSubLists.includes(lists.id)} onChange={() => handleIndividualCheckboxChange(lists.id)} />
          <div className="user-icon" onClick={() => handleIndividualCheckboxChange(lists.id)}>
            <Image className="icon" src={lists?.image_url || dynamicImage("svg/dummy-user.svg")} alt="User" onError={(e) => {e.target.src = dynamicImage("svg/dummy-user.svg");}}/>
            <span className="platform-icon">
              {lists.platform === "instagram" ? <Image src={dynamicImage("svg/insta.svg")} className="icon" /> : <Image src={dynamicImage("svg/youtube.svg")} className="icon" />}
            </span>
          </div>
          <div className="list-name">
            <h5>{lists?.full_name ? lists?.full_name : "Unknown"}</h5>
            <h6>
              {lists.platform === "instagram" ? <Instagram fontSize="small" /> : <YouTube fontSize="small" />}
              <span onClick={() => handleUrl(lists)}>@{lists?.platform_username}</span>
              <ArrowOutward fontSize="10px" className="ms-1" />
            </h6>
          </div>
        </div>
        {!isListSelected && (
          <div className="list-operations">
            <Image src={dynamicImage("svg/delete.svg")} alt="delete Icon" onClick={() => handleInfluencerDeleteClick(lists.id)} />
            <Image src={dynamicImage("svg/export.svg")} alt="export Icon" onClick={() => exportFavoriteListCsv(lists,false,true)} />
          </div>
        )}
      </li>
    ),
    [selectedSubLists, isListSelected, handleIndividualCheckboxChange, handleUrl, handleInfluencerDeleteClick, exportFavoriteListCsv]
  );

  const clearSelectionButton = (isListSelected || singleDelete) && (
    <div className="mt-3">
      <h5 className="list-title">
        {selectedSubLists.length} creator{selectedSubLists.length > 1 && "s"} selected
        <span onClick={clearSubListSelection} style={{ cursor: "pointer" }}>Clear selection</span>
      </h5>
      <Btn className="w-100 delete-list-btn" onClick={()=>{toggleDeleteList();toggleCreatorListModal()}}>
        <Image src={dynamicImage("svg/delete-error.svg")} alt="Delete Icon" /> Delete
      </Btn>
    </div>
  );

  const listHeader = !isListSelected && (
    <div className="list-header-wrap">
      <div className="list-title-wrap">
        <h5>{subList?.name}</h5>
        <h6>{subList?.creators?.length} <span>creators · Created</span> {formatDate(subList?.createdAt)}</h6>
      </div>
      <div className="list-operations">
        <Image src={dynamicImage("svg/delete.svg")} alt="delete Icon" onClick={() => handleDeleteClick(subList?.id)} />
        <Image src={dynamicImage("svg/export.svg")} alt="export Icon" onClick={() => exportFavoriteListCsv(subList,true)} />
      </div>
    </div>
  );

  return (
    <div className="inner-offcanvas">
      <div>
        <div className="back-btn" onClick={handleBack}>
          <Image className="back-arrow" src={dynamicImage("svg/arrow-left.svg")} />
        </div>
        {clearSelectionButton || listHeader}
        <FormGroup className="mt-lg-4 mt-3">
          <OutlinedInput inputRef={inputRef} startAdornment={<Image src={dynamicImage("svg/search-dark.svg")} />} endAdornment={searchSubList?.length > 0 && <Clear className="cursor-pointer" onClick={handleClearSearch} />} className="w-100 list-search" placeholder="Search your creator ..." value={searchSubList} onChange={handleSearchChange} />
        </FormGroup>
        <div className="total-list-count">
          <input type="checkbox" className="check-box" checked={selectAll} onChange={handleSelectAllChange} />
          <label htmlFor="total-count">{filteredSubList?.length} creators</label>
        </div>
      </div>
      <ul className="create-list-wrapper inner-list-wrapper scroll-bar">{filteredSubList?.map(renderUserRow)}</ul>
    </div>
  );
};
