export const FindCreatorsData = [
  { id: 1, name: "Emily Brown", title: "influencers-1", creator: "YouTube", category: "Travel", text: "Travel blogger and adventure seeker influencer", rate: 500, location: "London, UK" },
  { id: 2, name: "Emily Brown", title: "influencers-2", creator: "Twitter", category: "Fashion", text: "Fashion enthusiast and style icon influencer", rate: 1300, location: "New York, USA" },
  { id: 3, name: "John Smith", title: "influencers-3", creator: "TikTok", category: "Fitness", text: "Fitness coach and lifestyle mentor influencer", rate: 750, location: "Los Angeles, USA" },
  { id: 4, name: "Jane Doe", title: "influencers-4", creator: "Instagram", category: "Fashion", text: "Fashion enthusiast and style icon influencer", rate: 1500, location: "London, UK" },
  { id: 5, name: "Emma Taylor", title: "influencers-5", creator: "Instagram", category: "Fitness", text: "Fitness coach and lifestyle mentor influencer", rate: 1200, location: "Los Angeles, USA" },
  { id: 6, name: "Emily Brown", title: "influencers-6", creator: "TikTok", category: "Fashion", text: "Travel blogger and adventure seeker influencer", rate: 500, location: "London, UK" },
  { id: 7, name: "Ryan Miller", title: "influencers-7", creator: "Instagram", category: "Travel", text: "Travel blogger and adventure seeker influencer", rate: 750, location: "New York, USA" },
  { id: 8, name: "Emma Taylor", title: "influencers-8", creator: "YouTube", category: "Education", text: "Education enthusiast and style icon influencer", rate: 1000, location: "London, UK" },
  { id: 9, name: "Jane Doe", title: "influencers-9", creator: "Instagram", category: "Fashion", text: "Fashion enthusiast and style icon influencer", rate: 1450, location: "Los Angeles, USA" },
  { id: 10, name: "Emma Taylor", title: "influencers-10", creator: "YouTube", category: "Education", text: "Education enthusiast and style icon influencer", rate: 900, location: "New York, USA" },
  { id: 11, name: "Emily Brown", title: "influencers-11", creator: "TikTok", category: "Art", text: "Art coach and lifestyle mentor influencer", rate: 1500, location: "Los Angeles, USA" },
  { id: 12, name: "Emily Brown", title: "influencers-12", creator: "Instagram", category: "Fitness", text: "Fitness coach and lifestyle mentor influencer", rate: 1300, location: "London, UK" },
  { id: 13, name: "Ryan Miller", title: "influencers-13", creator: "Instagram", category: "Travel", text: "Travel blogger and adventure seeker influencer", rate: 1150, location: "New York, USA" },
  { id: 14, name: "John Smith", title: "influencers-14", creator: "TikTok", category: "Art", text: "Art coach and lifestyle mentor influencer", rate: 1200, location: "Los Angeles, USA" },
  { id: 15, name: "Emily Brown", title: "influencers-15", creator: "TikTok", category: "Fashion", text: "Fashion enthusiast and style icon influencer", rate: 750, location: "London, UK" },
  { id: 16, name: "Emily Brown", title: "influencers-16", creator: "YouTube", category: "Education", text: "Education enthusiast and style icon influencer", rate: 1500, location: "New York, USA" },
];

export const CreatorSocialData = [
  {
    platform: "Instagram",
    prices: [
      { type: "Instagram Post", price: "$1,500 per post" },
      { type: "Instagram Story", price: "$800 per story" },
    ],
  },
  {
    platform: "YouTube",
    prices: [
      { type: "YouTube Video", price: "$3,000 per video" },
      { type: "Spotlight", price: "$800 per spotlight" },
    ],
  },
];

export const CollaborationsData = [
  {
    brandName: "TravelMates",
    brandTagline: "Your Adventure Awaits",
    brandImage: "svg/brand-svg/travelmates.svg",
  },
  {
    brandName: "HealthyBites",
    brandTagline: "Snack Smart, Live Well",
    brandImage: "svg/brand-svg/healthybites.svg",
  },
];

// overviewData.json
export const OverviewData = {
  niche: "Traveling",
  platforms: [
    {
      name: "Instagram",
      followers: "800K",
      engagement: "1%",
    },
    {
      name: "YouTube",
      followers: "800K",
      engagement: "1%",
    },
  ],
  gender: "Female",
  location: "New York, USA",
};

export const youAreOptions = ["creator", "brand"];
export const platformOptions = ["instagram", "youtube", "tikTok"];
export const categoryOptions = ["Technology", "Health", "Finance"];
export const genderOptions = ["male", "female", "other"];
export const accountOptions = ["public", "private", "business"];

export const platformOption = [
  { image: "svg/insta.svg", title: "instagram",  disabled: false },
  { image: "svg/youtube.svg", title: "youtube (coming soon !)", disabled: true },
];

export const topTopics = ["The Shawshank Redemption", "The Godfather", "The Godfather: Part II", "The Dark Knight", "12 Angry Men", "Schindler's List", "Pulp Fiction", "The Lord of the Rings: The Return of the King"];

export const countries = ["United States", "India", "Canada", "United Kingdom", "Australia", "Germany"];

export const cities = ["New York", "Los Angeles", "Chicago", "Houston", "Philadelphia"];

export const creatorFollowerOption = [
  { title: "1k", value: 1000 },
  { title: "5K", value: 5000 },
  { title: "10K", value: 10000 },
  { title: "25K", value: 25000 },
  { title: "50K", value: 50000 },
  { title: "100K", value: 100000 },
  { title: "250K", value: 250000 },
  { title: "500K", value: 500000 },
  { title: "1M", value: 1000000 },
  { title: "3M", value: 3000000 },
];


export const creatorContentOption = [
  { title: "100", value: 100 },
  { title: "500", value: 500 },
  { title: "1k", value: 1000 },
  { title: "5K", value: 5000 },
  { title: "10K", value: 10000 },
  { title: "25K", value: 25000 },
  { title: "50K", value: 50000 },
  { title: "100k", value: 100000 },
];

export const averageLikeOption = [
  { title: "100", value: 100 },
  { title: "500", value: 500 },
  { title: "1k", value: 1000 },
  { title: "5K", value: 5000 },
  { title: "25K", value: 25000 },
  { title: "50K", value: 50000 },
  { title: "100K", value: 100000 },
  { title: "500K", value: 500000 },
  { title: "1M", value: 1000000 },
];

export const averageCommentOption = [
  { title: "50", value: 50 },
  { title: "100", value: 100 },
  { title: "500", value: 500 },
  { title: "1k", value: 1000 },
  { title: "5K", value: 5000 },
  { title: "15K", value: 15000 },
];

export const averageViewOption = [
  { title: "100", value: 100 },
  { title: "500", value: 500 },
  { title: "1k", value: 1000 },
  { title: "5K", value: 5000 },
  { title: "25K", value: 25000 },
  { title: "50K", value: 50000 },
  { title: "100K", value: 100000 },
  { title: "500K", value: 500000 },
  { title: "1M", value: 1000000 },
];