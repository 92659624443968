import toast from "react-hot-toast";
import React, { Fragment, useEffect, useState } from "react";

import getStorage, { KEYS } from "../../../Helper/getStorage";
import LoginContainer from "../../../Component/Auth/Login";

const LogIn = () => {
  const { getItem, clearItems } = getStorage()
  const [resetVerified, setResetVerified] = useState(getItem(KEYS.RESET));

  useEffect(() => {
    if (resetVerified !== null && resetVerified) {
      const timer = setTimeout(() => {
        clearItems();
        setResetVerified(null);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [clearItems, resetVerified]);

  return (
    <Fragment>
      <LoginContainer />
      {resetVerified && toast.success("Your password has been reset successfully.")}
    </Fragment>
  );
};

export default LogIn;
