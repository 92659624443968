import { Component } from "react";
import { unstable_batchedUpdates } from "react-dom";

import { Routers } from "./Routes";
import { CustomToaster } from "./Helper";
import { AuthContextProvider } from "./Context/Auth/AuthContext";
import { LayoutContextProvider } from "./Context/Layout/LayoutContext";
import { CreatorContextProvider } from "./Context/Creator/CreatorContext";
import { CreatorFavoriteProvider } from "./Context/Creator/CreatorFavoriteContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { CreatorProfileContextProvider } from "./Context/Creator/CreatorProfileContext";
import { AdminAuthContextProvider } from "./Context/Auth/AdminAuthContext";

unstable_batchedUpdates(() => {
  console.error = () => {};
});

class ErrorBoundary extends Component {
  componentDidCatch(error) {
    if (error.message.includes("ToastContainer")) {
      return;
    }
  }

  render() {
    return this.props.children;
  }
}

const queryClient = new QueryClient();

function App() {
  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <AdminAuthContextProvider>
          <AuthContextProvider>
            <LayoutContextProvider>
              <CreatorContextProvider>
                <CreatorFavoriteProvider>
                  <CreatorProfileContextProvider>
                    <CustomToaster />
                    <Routers />
                  </CreatorProfileContextProvider>
                </CreatorFavoriteProvider>
              </CreatorContextProvider>
            </LayoutContextProvider>
          </AuthContextProvider>
        </AdminAuthContextProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

export default App;
