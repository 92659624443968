import { Container } from "reactstrap";
import React, { useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";

import CreatorCard from "../Common/CreatorCard";
import { creatorsSliderData, creatorsSliderDataBreakPoint } from "../../Data/HomeData";

const CreatorsSection = () => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const swiperRef = useRef(null);

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.params.navigation.prevEl = prevRef.current;
      swiperRef.current.params.navigation.nextEl = nextRef.current;
      swiperRef.current.navigation.destroy();
      swiperRef.current.navigation.init();
      swiperRef.current.navigation.update();
    }
  }, [swiperRef, prevRef, nextRef]);

  return (
    <section>
      <Container className="arrow-style-1 position-relative">
        <h3 className="title text-center">Relevent Influencers, Fast</h3>
        <p className="sub-title text-center sb-space">Uncover Your Perfect Creators, Hidden Gems Included</p>
        <div className="position-relative h-100">
          <button ref={prevRef} className="swiper-button-prev"></button>
          <Swiper
            ref={swiperRef}
            className="creators-slider h-100"
            slidesPerView={4}
            loop={true}
            spaceBetween={20}
            modules={[Navigation, Autoplay]}
            // autoplay={{ delay: 2500, disableOnInteraction: false }}
            // speed={1500}
            navigation={{ prevEl: prevRef.current, nextEl: nextRef.current }}
            onInit={(swiper) => {
              swiperRef.current = swiper; 
            }}
            breakpoints={creatorsSliderDataBreakPoint}
          >
            {creatorsSliderData.map((item) => (
              <SwiperSlide className="h-100" key={item.id}>
                <CreatorCard item={item} data={creatorsSliderData} />
              </SwiperSlide>
            ))}
          </Swiper>
          <button ref={nextRef} className="swiper-button-next"></button>
        </div>
      </Container>
    </section>
  );
};

export default CreatorsSection;
