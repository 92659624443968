import { Checklist, Clear } from "@mui/icons-material";
import { OutlinedInput } from "@mui/material";
import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { FormGroup, Modal, ModalBody, ModalHeader } from "reactstrap";

import { Btn, Image } from "../../../AbstractElement";
import { API } from "../../../Api/ApiList";
import { useGetApi } from "../../../Api/useGetApi";
import { usePostApi } from "../../../Api/usePostApi";
import { useCreatorFavoriteContext } from "../../../Context/Creator/CreatorFavoriteContext";
import { dynamicImage } from "../../../Helper";
import { DefaultContent } from "../../Common/Table/DefaultContent";

export const TableRowAddModal = () => {
  const { setIsAllFilterChecked, setSelectedFilterRows, setIsAllProfileChecked, setSelectedProfileRows, setTableRowAddModal, selectedProfileRows, setCreatorList, setSelectedLists, searchList, setSearchList, tableSelect, selectedFilterRows, selectedLists, handleCheckboxChange, filteredList, tableRowAddModal, toggleTableRowAddModal, toggleListAddModal, onExitCreatorListCanvas } = useCreatorFavoriteContext();
  const isListSelected = selectedLists.length > 0;
  const selectedRows = tableSelect === "filters" ? selectedFilterRows : tableSelect === "profile" ? selectedProfileRows : "list";

  // Get favorites list
  const { data, fetchStatus } = useGetApi({ url: API.creator.getInfluencerFavoriteListApi, options: { refetchOnWindowFocus: false } });
  const isLoading = fetchStatus === "fetching" || false;

  useEffect(() => {
    data !== undefined && setCreatorList(data);
  }, [data, setCreatorList]);

  // Add creators to list
  const influencerIds = (tableSelect === "filters" ? selectedFilterRows : selectedProfileRows)?.map((row) => row.id);
  const { mutate, isPending: addToListLoading } = usePostApi({
    url: API.creator.addInfluencerToFavoriteListApi,
    data: { favorite_list_ids: selectedLists, influencer_ids: influencerIds },
    onSuccess: () => {
      toast.success("Creators added successfully");
      setTableRowAddModal(false);
      setSelectedLists([]);
      if (tableSelect === "filters") {
        setIsAllFilterChecked(false);
        setSelectedFilterRows([]);
      } else if (tableSelect === "profile") {
        setIsAllProfileChecked(false);
        setSelectedProfileRows([]);
      }
    },
  });

  useEffect(() => {
    if (tableRowAddModal === true) {
      setSelectedLists([]);
    }
  }, [setSelectedLists, tableRowAddModal]);

  return (
    <Modal className="creator-addlist" centered isOpen={tableRowAddModal} toggle={toggleTableRowAddModal} onExit={onExitCreatorListCanvas}>
      {isLoading && (
        <div className="loading-overlay d-flex align-items-center justify-content-center gap-2 flex-column">
          <div class="custom-loader"></div>
          <div className="loading-message">Fetching for your favorite lists ...</div>
        </div>
      )}
      <ModalHeader toggle={toggleTableRowAddModal} className="border-0"></ModalHeader>
      <ModalBody className="addlist-wrap">
        <div className="list-wrap pt-0 align-items-center">
          <div className="list-detail">
            <h5>
              Add {selectedRows?.length} creator{selectedRows?.length > 1 && "s"} to list
            </h5>
          </div>
        </div>

        <div className={`add-list-loader ${isLoading ? "loading" : ""}`}>
          <Btn
            className="w-100 btn-pill-light"
            onClick={() => {
              toggleListAddModal();
              toggleTableRowAddModal();
            }}
          >
            <Image className="list-image" src={dynamicImage("svg/add.svg")} alt="add Icon" /> Create new list
          </Btn>
          <FormGroup className="mt-lg-4 mt-3">
            <OutlinedInput startAdornment={<Image src={dynamicImage("svg/search-dark.svg")} />} endAdornment={searchList.length > 0 && <Clear className="cursor-pointer" onClick={() => setSearchList("")} />} className="w-100 list-search" placeholder="Search your list ..." value={searchList} onChange={(e) => setSearchList(e.target.value)} />
          </FormGroup>
          {filteredList.length > 0 ? (
            <ul className="create-list-wrapper pe-2 scroll-bar">
              {filteredList?.map((lists, index) => (
                <li className={`user-row ${selectedLists.includes(lists.id) ? "active" : ""}`} key={index} onClick={() => handleCheckboxChange(lists.id)}>
                  <div className="user-list">
                    <input type="checkbox" className="check-box" checked={selectedLists.includes(lists.id)} onClick={(e) => e.stopPropagation()} onChange={() => handleCheckboxChange(lists.id)} />
                    <div className="icon-box">
                      <Checklist />
                    </div>
                    <div className="list-name">
                      <h5>{lists.name}</h5>
                      <h6>{lists.creators?.length} creators</h6>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <div className="p-5">{!isLoading && <DefaultContent mainClass="favorite-list" image="no-list" title="No favorite list added yet." />}</div>
          )}
          <Btn block className="btn-theme mt-4" disabled={addToListLoading || !isListSelected} onClick={mutate} loading={addToListLoading}>
            Add {selectedLists.length > 0 && `to ${selectedLists.length} list${selectedLists?.length > 1 ? "s" : ""}`}
          </Btn>
        </div>
      </ModalBody>
    </Modal>
  );
};
