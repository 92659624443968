export const brandSliderData = [
  { id: 1, title: "adidas" },
  { id: 2, title: "amazon" },
  { id: 3, title: "cocacola" },
  { id: 4, title: "nike" },
  { id: 5, title: "samsung" },
  { id: 6, title: "sony" },
  { id: 7, title: "starbucks" },
];

export const brandSliderDataBreakPoint = {
  0: {
    slidesPerView: 2,
  },
  475: {
    slidesPerView: 3,
  },
  768: {
    slidesPerView: 4,
  },
  1040: {
    slidesPerView: 5,
  },
  1340: {
    slidesPerView: 6,
  },
  1920: {
    slidesPerView: 6,
  },
};

export const BrandReviewSliderData = [
  { id: 1, image: "EcoWear", brandName: "EcoWear", subName: "Sustainable Fashion for a Better Tomorrow", date: "June 15, 2024", text: `"This platform has revolutionized my collaborations with top brands. The seamless connection and easy-to-use interface have significantly boosted my engagement and reach."` },
  { id: 2, image: "chicnest", brandName: "ChicNest", subName: "Stylish Living Made Easy", date: "july 3, 2024", text: "ChicNest offers a wide range of affordable, stylish home décor items that ChicNest offers a wide range of affordable, stylish home décor items that" },
  { id: 3, image: "luxurylounge", brandName: "LuxuryLounge", subName: "Indulge in Elegance", date: "june 30, 2024", text: "LuxuryLounge offers a curated selection of high-end furniture and décor items Successfully partnered with esteemed brands, creating impactful and engaging content that drives high engagement. " },
];

export const CreatorReviewSliderData = [
  { id: 1, image: "1", brandName: "EcoWear", subName: "Sustainable Fashion for a Better Tomorrow", date: "June 15, 2024", text: `"This platform has revolutionized my collaborations with top brands. The seamless connection and easy-to-use interface have significantly boosted my engagement and reach."` },
  { id: 2, image: "2", brandName: "ChicNest", subName: "Stylish Living Made Easy", date: "july 3, 2024", text: "ChicNest offers a wide range of affordable, stylish home décor items that ChicNest offers a wide range of affordable, stylish home décor items that" },
  { id: 3, image: "3", brandName: "LuxuryLounge", subName: "Indulge in Elegance", date: "june 30, 2024", text: "LuxuryLounge offers a curated selection of high-end furniture and décor items Successfully partnered with esteemed brands, creating impactful and engaging content that drives high engagement. " },
];

export const categoriesSliderData = [
  { id: 1, title: "Beauty.jpg", text: "Beauty" },
  { id: 2, title: "fitness.jpg", text: "Fitness" },
  { id: 3, title: "Nature.jpg", text: "Nature" },
  { id: 4, title: "Advanture.jpg", text: "Advanture" },
  { id: 5, title: "Sports.jpg", text: "Sports" },
  { id: 6, title: "Fashion.jpg", text: "Fashion" },
  { id: 7, title: "techno.jpg", text: "Technology" },
  { id: 8, title: "Music.jpg", text: "Music" },
  { id: 9, title: "travelers.jpg", text: "Travelers" },
  { id: 10, title: "Photography.jpg", text: "Photography" },
  { id: 11, title: "Food.jpg", text: "Food" },
  { id: 12, title: "Nature.jpg", text: "Nature" },
  { id: 13, title: "Fashion.jpg", text: "Fashion" },
  { id: 14, title: "Advanture.jpg", text: "Advanture" },
  { id: 15, title: "Education.jpg", text: "Education" },
  { id: 16, title: "Lifestyle.jpg", text: "Lifestyle" },
  { id: 17, title: "Health.jpg", text: "Health" }
];

export const categoriesSliderDataBreakPoint = {
  0: {
    slidesPerView: 1,
  },
  375: {
    slidesPerView: 2,
  },
  576: {
    slidesPerView: 3,
  },
  767: {
    slidesPerView: 3,
  },
  1199: {
    slidesPerView: 4,
    spaceBetween: -20,
  },
  1920: {
    slidesPerView: 4,
  },
};

export const creatorsSliderData = [
  { id: 1, name: "Rohit Zinjurke", title: "influencer-23", creator: "Instagram", category: "YouTuber", text: "his work on TikTok, Instagram, and YouTube", location: "Surat, India" },
  { id: 2, name: "Mortal", title: "influencer-18", creator: "Instagram", category: "Gaming", text: "Travel blogger and adventure seeker...", location: "Mumbai, India" },
  { id: 3, name: "Ankur Warikoo", title: "influencer-22", creator: "Instagram", category: "Finance", text: "Entrepreneur, author, public speaker, and content creator", location: "Delhi, India" },
  { id: 4, name: "Purav jha", title: "influencer-24", creator: "Instagram", category: "Entertainment", text: "Purav Jha is a rising star in the Indian digital landscape, known for his engaging content as a social media influencer and actor", location: "Delhi, India" },
  { id: 5, name: "Payal Gaming", title: "influencer-25", creator: "Instagram", category: "Gaming", text: "Payal Gaming has gained popularity at a young age. She has also diversified her content by streaming various other games recently.", location: "Mumbai, India" },
];

export const creatorsSliderDataBreakPoint = {
  0: {
    slidesPerView: 1,
  }, 
  375: {
    slidesPerView: 2,
    spaceBetween: 15,
  },
  576: {
    slidesPerView: 2,
    autoplay: {
      delay: 500,
      disableOnInteraction: false,
    },
  },
  767: {
    slidesPerView: 3,
  },
  1399: {
    slidesPerView: 4,
  },
  1540: {
    slidesPerView: 4,
  },
  1920: {
    slidesPerView: 4,
  },
};

export const servicesData = [
  {
    id: 1,
    imgSrc: "svg/user-icon.svg",
    title: "Create Your Account",
    description: "Sign up with your email to get started.",
  },
  {
    id: 2,
    imgSrc: "svg/search-icon.svg",
    title: "Find Your Match",
    description: "Apply advanced filters or try our new AI recommendation (coming soon).",
  },
  {
    id: 3,
    imgSrc: "svg/collab-icon.svg",
    title: "Shortlist your choices",
    description: "Add to your favourite's list or export the results as csv.",
  },
  {
    id: 4,
    imgSrc: "svg/share-feedback.svg",
    title: "Share Feedback",
    description: "We’d love to hear from you! Share your suggestions to help us improve",
  },
];

export const topBrandsData = [
  {
    id: 1,
    imgSrc: "svg/brand-svg/EcoWear.svg",
    title: "EcoWear",
    subtitle: "Fuel Your Fitness Journey",
    description: "EcoWear is a leading brand committed to sustainable fashion.",
    categories: ["Cosmetics", "Health"],
    location: "New York, USA",
  },
  {
    id: 2,
    imgSrc: "svg/brand-svg/FitLifeGear.svg",
    title: "FitLife Nutrition",
    subtitle: "Sustainable Fashion for a Better Tomorrow",
    description: "FitLife Nutrition is dedicated to providing sustainable fashion.",
    categories: ["Beauty", "Cosmetics"],
    location: "New York, USA",
  },
  {
    id: 3,
    imgSrc: "svg/brand-svg/FitLifeNutrition.svg",
    title: "FitLife Gear",
    subtitle: "Gear Up for a Better You",
    description: "FitLife Gear offers premium fitness gear for your journey.",
    categories: ["Beauty", "Cosmetics"],
    location: "New York, USA",
  },
  {
    id: 4,
    imgSrc: "svg/brand-svg/Glitz -Glam.svg",
    title: "Glitz & Glam Beauty",
    subtitle: "Elevating Beauty Standards Worldwide",
    description: "Glitz & Glam Beauty is a leading beauty and cosmetics brand.",
    categories: ["Beauty", "Health"],
    location: "New York, USA",
  },
];

export const influencerSliderData = [
  { id: 1, name: "Jyoti Dave", title: "influencer-17", category: "Fashion", text: "Mrs India Dubai Intl 2017, She has a strong background as an influencer, entrepreneur, and content creator, with ties to both India and Dubai", location: "Dubai, UAE" },
  { id: 2, name: "Mortal", title: "influencer-18", category: "Gaming", text: "Travel blogger and adventure seeker...", location: "Mumbai, India" },
  { id: 3, name: "Sonia Garg", title: "influencer-19", category: "Fashion", text: "Fashion influencer who blends travel and fashion while promoting self-expression and sustainable choices.", location: "Delhi, India" },
  { id: 4, name: "Arfaz iqbal", title: "influencer-20", category: "Entertainment", text: "Actor, Radio & Tv presenter, influencer.", location: "Dubai, UAE" },
  { id: 5, name: "City1016", title: "influencer-21", category: "Entertainment", text: "Uae’s no.1 bollywood music station. desi at heart, the station’s vibrant presenters stay connected to their listeners. part of arn.", location: "Dubai, UAE" },
];

export const influencerSliderDataBreakPoint = {
  0: {
    slidesPerView: 1,
  },
  375: {
    slidesPerView: 2,
    spaceBetween: 15,
  },
  576: {
    slidesPerView: 2,
  },
  767: {
    slidesPerView: 3,
  },
  1399: {
    slidesPerView: 4,
  },
  1540: {
    slidesPerView: 4,
  },
  1920: {
    slidesPerView: 4,
  },
};

export const blogData = [
  {
    id: 1,
    date: "june 5, 2024",
    image: "blog/blog-1.jpg",
    title: "Boost Your Brand's Reach with Influencer",
    description: "Discover effective strategies to amplify your brand's presence through influencer partnerships",
  },
  {
    id: 2,
    date: "june 10, 2024",
    image: "blog/blog-2.jpg",
    title: "Top Trends in Influencer Marketing for 2024",
    description: "Discover effective strategies to amplify your brand's presence through influencer partnerships Discover effective strategies hips",
  },
  {
    id: 3,
    date: "june 15, 2024",
    image: "blog/blog-3.jpg",
    title: "How Nike Boost Sales with Influencer",
    description: "Discover effective strategies to amplify your brand's presence through influencer partnerships",
  },
];

export const testimonialData = [
  { id: 1, title: "user/4.jpg", text: `"This platform has revolutionized my collaborations with top brands. The seamless connection and easy-to-use interface have significantly boosted my engagement and reach."`, category: "Fashion Influencer" },
  { id: 2, title: "user/5.jpg", text: `"This platform has transformed my cooperation with leading companies. The smooth connection and user-friendly UI have considerably increased my engagement and reach."`, category: "Travel Influencer" },
  { id: 3, title: "user/6.jpg", text: `"This platform has completely transformed my cooperation with leading companies. My engagement and reach have increased dramatically due to the smooth integration and user-friendly design."`, category: "Fitness Influencer" },
];

export const socialLinks = [
  { id: 1, name: "tiktok", icon: "svg/tiktok.svg" },
  { id: 2, name: "facebook", icon: "svg/facebook.svg" },
  { id: 3, name: "instagram", icon: "svg/instagram.svg" },
  { id: 4, name: "twitter", icon: "svg/twitter.svg" },
];

export const footerData = [
  {
    id: 1,
    title: "Discover",
    list: [
      { url: "#javascript", text: "Find Influencers" },
      { url: "#javascript", text: "Find Brands" },
      { url: "#javascript", text: "Case Studies" },
    ],
  },
  {
    id: 2,
    title: "About Us",
    list: [
      { url: "#javascript", text: "Our Story" },
      { url: "#javascript", text: "Team" },
      { url: "#javascript", text: "Careers" },
      { url: "#javascript", text: "Press" },
    ],
  },
  {
    id: 3,
    title: "Resources",
    list: [
      { url: "#javascript", text: "Latest Posts" },
      { url: "#javascript", text: "Trends" },
      { url: "#javascript", text: "Success Stories" },
      { url: "#javascript", text: "Tips & Guides" },
      { url: "#javascript", text: "Research" },
      { url: "#javascript", text: "Videos" },
    ],
  },
  {
    id: 4,
    title: "Help & Support",
    list: [
      { url: "#javascript", text: "Help Center" },
      { url: "#javascript", text: "Contact Us" },
      { url: "#javascript", text: "FAQs" },
      { url: "#javascript", text: "How it’s works" },
    ],
  },
  {
    id: 5,
    title: "Legal",
    list: [
      { url: "#javascript", text: "Terms of Service" },
      { url: "#javascript", text: "Privacy Policy" },
      { url: "#javascript", text: "Creator Terms of Use" },
      { url: "#javascript", text: "Creator Privacy Policy" },
    ],
  },
];

export const collaborationSliderDataBreakPoint = {
  0: {
    slidesPerView: 1,
    spaceBetween: 10,
  },
  475: {
    slidesPerView: 1,
    spaceBetween: 20,
  },
  764: {
    slidesPerView: 2,
    spaceBetween: 20,
  },
  1200: {
    slidesPerView: 2,
    spaceBetween: 30,
  },
  1920: {
    slidesPerView: 2,
    spaceBetween: 30,
  },
};
