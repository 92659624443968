import React, { Fragment } from "react";
import { OutlinedInput } from "@mui/material";
import { FormGroup } from "reactstrap";
import { Checklist, Clear } from "@mui/icons-material";

import { Btn, Image } from "../../../../AbstractElement";
import { dynamicImage } from "../../../../Helper";
import { useAutoFocus } from "../../../../Hooks/useAutoFocus";
import { useCreatorFavoriteContext } from "../../../../Context/Creator/CreatorFavoriteContext";
import { DefaultContent } from "../../../Common/Table/DefaultContent";

export const CreatorMainList = ({ isLoading }) => {
  const { exportFavoriteListCsv, toggleCreatorListModal, selectedLists, handleListClick, filteredList, searchList, setSearchList, handleDeleteClick, toggleDeleteList, handleCheckboxChange, clearSelection, creatorListModal, toggleListAddModal } = useCreatorFavoriteContext();
  const isListSelected = selectedLists.length > 0;
  const inputRef = useAutoFocus(creatorListModal);

  return (
    <Fragment>
      <div>
        {isListSelected ? (
          <div>
            <h5 className="list-title">
              {selectedLists.length} list{selectedLists.length > 1 && "s"} selected
              <span onClick={clearSelection} style={{ cursor: "pointer" }}>Clear selection</span>
            </h5>
            <Btn
              className="w-100 delete-list-btn"
              onClick={() => {
                toggleDeleteList();
                toggleCreatorListModal();
              }}
            >
              <Image src={dynamicImage("svg/delete-error.svg")} alt="add Icon" /> Delete
            </Btn>
          </div>
        ) : (
          <div>
            <h5 className="list-title">Your favorite lists</h5>
            <Btn className="w-100 btn-pill-light"
              onClick={() => {
                toggleListAddModal();
                toggleCreatorListModal();
              }}
            >
              <Image src={dynamicImage("svg/add.svg")} alt="add Icon" /> Create new list
            </Btn>
          </div>
        )}
        <FormGroup className="mt-lg-4 mt-3">
          <OutlinedInput inputRef={inputRef} startAdornment={<Image src={dynamicImage("svg/search-dark.svg")} />} endAdornment={searchList.length > 0 && <Clear className="cursor-pointer" onClick={() => setSearchList("")} />} className="w-100 list-search" placeholder="Search your favorite list ..." value={searchList} onChange={(e) => setSearchList(e.target.value)} />
        </FormGroup>
      </div>

      <ul className="create-list-wrapper pe-2 scroll-bar">
        {filteredList?.length > 0 ? (
          <Fragment>
            {filteredList?.map((lists) => (
              <li className={`user-row ${selectedLists?.includes(lists.id) ? "active" : ""}`} key={lists.id}>
                <div className="user-list">
                  <input type="checkbox" className="check-box" checked={selectedLists.includes(lists.id)} onChange={() => handleCheckboxChange(lists.id)} />
                  <div className="icon-box" onClick={() => handleCheckboxChange(lists.id)}>
                    <Checklist />
                  </div>
                  <div className="list-name" onClick={() => handleListClick(lists)}>
                    <h5>{lists.name}</h5>
                    <h6>{lists.creators?.length} creators</h6>
                  </div>
                </div>
                {!isListSelected && (
                  <div className="list-operations">
                    <Image src={dynamicImage("svg/delete.svg")} alt="add-list Icon" onClick={() => handleDeleteClick(lists.id)} />
                    <Image src={dynamicImage("svg/export.svg")} alt="add-list Icon" onClick={() => exportFavoriteListCsv(lists, true)} />
                  </div>
                )}
              </li>
            ))}
          </Fragment>
        ) : (
          <Fragment>
            {!isLoading && (
              <li className="favorite-no-data">
                <DefaultContent mainClass="favorite-canvas" image="no-list" title="No favorite list added yet." />
              </li>
            )}
          </Fragment>
        )}
      </ul>
    </Fragment>
  );
};