import React, { Fragment, lazy, Suspense } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { useAdminAuthContext } from "../Context/Auth/AdminAuthContext";
import { useAuthContext } from "../Context/Auth/AuthContext";
import Loader from "../Layout/Loader";
import { AdminPrivateRoute, PrivateRoute } from "./PrivateRouters";
import { AuthRoutes, ROUTES, routes } from "./Routes";

const LazyLayoutRoutes = lazy(() => import("./LayoutRoutes").then((module) => ({ default: module.LayoutRoutes })));
const LazyAdminLayoutRoutes = lazy(() => import("./LayoutRoutes").then((module) => ({ default: module.AdminLayoutRoutes })));
const Layout = lazy(() => import("../Layout/WebLayout"));

export const Routers = () => {
  const { isAuthenticated } = useAuthContext();
  const { isAdminAuthenticated } = useAdminAuthContext();

  return (
    <BrowserRouter basename={ROUTES.Home}>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route element={<Layout />}>
            {routes.map((item, index) => (
              <Route key={index} path={item.path} element={item.element} />
            ))}
          </Route>

          {isAuthenticated ? (
            <Fragment>
              <Route element={<PrivateRoute />}>
                <Route path="/*" element={<LazyLayoutRoutes />} />
              </Route>
            </Fragment>
          ) : (
            <Fragment>
              {isAdminAuthenticated ? (
                <Fragment>
                  <Route element={<AdminPrivateRoute />}>
                    <Route path="/*" element={<LazyAdminLayoutRoutes />} />
                  </Route>
                </Fragment>
              ) : (
                AuthRoutes.map(({ path, element }, i) => <Route key={i} path={path} element={element} />)
              )}
            </Fragment>
          )}
          <Route path="*" element={<Navigate to={ROUTES.Home} />} />
        </Routes>   
      </Suspense>
    </BrowserRouter>
  );
};
