import React, { useEffect, useState } from "react";
import { ClickAwayListener, Tooltip } from "@mui/material";
import { Add, Check, InfoOutlined } from "@mui/icons-material";

const FilterWrapper = ({ children, title, toolTip, disabled = false,taglNews=false, selected = false }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    if (!disabled) setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const [position, setPosition] = useState("top-start");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 576) {
        setPosition("top");
      } else {
        setPosition("top-start");
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <li className={`list-row ${open ? "active" : ""} ${disabled ? "disabled" : ""}`}  >
      <div className={`select-child`} >
        <div className="select-inner">
          <div className={`inner-icon-line ${open ? "active" : ""} ${disabled ? "disabled" : ""} ${selected ? "selected" : ""}`} onClick={handleOpen}>
            {selected ? <Check className="check-icon" /> : <Add className="plus-icon" fontSize="small" />}
            {title} {taglNews &&  <span className="taglNews">New !</span> }
            <Tooltip title={toolTip} placement={position}>
              <span>
                <InfoOutlined className="info-icon" fontSize="small" />
              </span>
            </Tooltip>
            {disabled && <span className="coming-soon">(coming soon)</span>}
          </div>
        </div>
      </div>
      {open && (
        <ClickAwayListener onClickAway={handleClose}>
          <div className="select-child custom-select-child">
            <div className="select-inner">
              <div>{children}</div>
            </div>
          </div>
        </ClickAwayListener>
      )}
    </li>
  );
};

export default FilterWrapper;
