import React from "react";
import { Link } from "react-router-dom";
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";

import { dynamicImage } from "../../../Helper";
import { ROUTES } from "../../../Routes/Routes";
import { Image } from "../../../AbstractElement";
import { useAuthContext } from "../../../Context/Auth/AuthContext";
import AuthForm from "./AuthForm";

const SignupContainer = () => {
  const { signUpTab, setActiveSignUpTab } = useAuthContext();

  return (
    <section className="auth-section">
      <Row className="auth-main g-0">
        <Col lg={7} xs={12}>
          <div className="auth-form">
            <div>
              <Link to={ROUTES.Home}>
                <Image className="logo" src={dynamicImage("logo.svg")} />
              </Link>
              <h2>Create Your Account</h2>
              <h6>Select your account type to get started.</h6>
              <Nav tabs>
                {/* <NavItem>
                  <NavLink href="#" className={signUpTab === 1 ? "active" : ""} onClick={() => setActiveSignUpTab(1)}>
                    Creators
                  </NavLink>
                </NavItem> */}
                <NavItem>
                  <NavLink href="#" className={signUpTab === 1 ? "active" : ""} onClick={() => setActiveSignUpTab(1)}>
                   Agency / Brand
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={signUpTab}>
                {/* <TabPane tabId={1}>
                  <AuthForm account="creator" />
                </TabPane> */}
                <TabPane tabId={1}>
                  <AuthForm account="brand" />
                </TabPane>
              </TabContent>
              <div className="divider">
                <span>or</span>
              </div>
              <div className="create-wrap">
                <h6>Already have an Account?</h6>
                <Link to={ROUTES.Auth.Login}>Sign in</Link>
              </div>
            </div>
          </div>
        </Col>
        <Col lg={5} className="d-lg-inline-block d-none">
          <div className="image-main">
            <div className="image-contain"></div>
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default SignupContainer;
