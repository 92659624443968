import React from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { Col, Form, FormGroup, Row } from "reactstrap";

import { dynamicImage } from "../../../Helper";
import { ROUTES } from "../../../Routes/Routes";
import { Btn, Image } from "../../../AbstractElement";
import { useAuthContext } from "../../../Context/Auth/AuthContext";
import useApiState from "../../../Hooks/useApiState";

const ForgetPasswordContainer = () => {
  const { ForgetPassSchema, handleForgetPassword } = useAuthContext();
  const navigate = useNavigate();
  const { loading, setLoading } = useApiState()

  const {register,handleSubmit,formState: { errors }} = useForm({ resolver: yupResolver(ForgetPassSchema) });

  const onSubmit = (data) => handleForgetPassword({ data, navigate, setLoading });

  return (
    <section className="verification-section p-0">
      <Row className="m-0 h-100">
        <Col xxl="6" lg="7" md="8" xs="12" className="mx-auto p-0">
          <div className="verification-form">
            <div className="px-3">
              <Image className="auth-gif" src={dynamicImage("gif/forgot_pass.gif")} />
              <div className="title">
                <h3>Forgot your password</h3>
                <p className="w-100">Enter your email address below to receive a Code reset link.</p>
              </div>
              <Form onSubmit={handleSubmit(onSubmit)} noValidate>
                <FormGroup className="position-relative">
                  <Image className="form-icon" src={dynamicImage("svg/sms.svg")} />
                  <input {...register("email")} type="email" className="form-control" placeholder="Enter Email Address" />
                  {errors.email && (
                    <span className="required-note">
                      <small>{errors.email.message}</small>
                    </span>
                  )}
                </FormGroup>
                <div className="action-btn pt-3">
                  <Btn block className="btn-theme" type="submit" loading={loading} disabled={loading}>
                    Verify
                  </Btn>
                  <Link to={ROUTES.Auth.Login} className="btn w-100 mt-sm-3 mt-2">
                    <Image className="back-arrow" src={dynamicImage("svg/arrow-left.svg")} />
                    Back to Sign in
                  </Link>
                </div>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default ForgetPasswordContainer;
