import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import React, { Fragment, useEffect } from "react";

import { ROUTES } from "../../../Routes/Routes";
import getStorage, { KEYS } from "../../../Helper/getStorage";
import EmailVerifyContainer from "../../../Component/Auth/EmailVerify";

const EmailVerify = () => {
  const { getItem, removeItem } = getStorage();
  const emailVerified = getItem(KEYS.EMAIL);
  const navigate = useNavigate();

  useEffect(() => {
    if (!emailVerified) {
      navigate(ROUTES.Auth.Login);
      toast.error("Page Not Found !!!");
    }

    return () => {
      removeItem(KEYS.EMAIL);
    };
  }, [emailVerified, navigate, removeItem]);

  return <Fragment>{emailVerified && <EmailVerifyContainer />}</Fragment>;
};

export default EmailVerify;
