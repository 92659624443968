import { useQuery } from "@tanstack/react-query";
import { axiosInstance, useErrorMessageHandler } from "./middleware";

export const useGetApi = ({ url, params, options, tokenType }) => {
  const { handleError } = useErrorMessageHandler();

  const query = useQuery({
    queryKey: [url, params],
    queryFn: async () => {
      try {
        const response = await axiosInstance.get(url, {
          params,
          tokenType,
        });
        return response.data;
      } catch (error) {
        handleError(error,tokenType);
      }
    },
    ...options,
  });

  return query;
};
