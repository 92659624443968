import React from "react";

import { dynamicImage } from "../../Helper";
import { Col, Container, Row } from "reactstrap";
import { Image } from "../../AbstractElement";
import { servicesData } from "../../Data/HomeData";
import { Link } from "react-router-dom";
import { ROUTES } from "../../Routes/Routes";
// import { ArrowRightAlt } from "@mui/icons-material";

const ServiceSection = () => {
  return (
    <section className="service-section st-space sb-space">
      <Image className="img-fluid service-bg" src={dynamicImage("service.png")} alt="icon" />
      <Container>
        <Row className="g-lg-5 g-4 align-items-center">
          <Col lg={5}>
            <div className="service-detail">
              <h3>How it Works</h3>
              <p className="pt-0 pb-2">Built for brands and agencies.</p>
              <p className="p-0">Search for the perfect influencers across Instagram</p>
              <p className="custom-badge">
              Youtube <sup>coming soon</sup></p>
              {/* <ul>
                <li><h3>100+</h3>Trusted Brands</li>
                <li><h3>20k+</h3>Trusted Influencers</li>
                <li><h3>50+</h3>Reached Campaign</li>
              </ul> */}
              <div className="home-btns justify-content-start">
                <Link to={ROUTES.Pages.Creator} className="btn btn-theme d-inline-block">Try it now 
                {/* <ArrowRightAlt fontSize="large" /> */} 
                </Link>
              </div>
            </div>
          </Col>
          <Col lg={6} className="offset-lg-1 offset-0">
            <Row className="service-row g-4">
              {servicesData.map((service) => (
                <Col sm={6} key={service.id}>
                  <div className="service-box">
                    <div className="service-img">
                      <Image className="img-fluid" src={dynamicImage(service.imgSrc)} alt="icon" />
                    </div>
                    <h5>{service.title}</h5>
                    <p>{service.description}</p>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ServiceSection;
