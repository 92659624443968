import { useState } from "react";
import { scrollToTop } from "../Helper";

const useApiState = (props) => {
  const { defaultLimit } = props || {};

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [params, setParams] = useState({});
  const [sortOrder, setSortOrder] = useState();
  const [totalPages, setTotalPages] = useState();
  const [sortColumn, setSortColumn] = useState({});
  const [loading, setLoading] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [limit, setLimit] = useState(defaultLimit || 25);

  const handleSort = (column) => {
    if (sortColumn !== column) {
      setSortColumn(column);
      setSortOrder("desc");
    } else {
      setSortOrder((prevSortOrder) => (prevSortOrder === "desc" ? "asc" : "desc"));
    }
  };

  const handleLimitChange = (e) => {
    scrollToTop(220);
    setLimit(Number(e.target.value));
    setPage(1);
  };

    // Function to set default sort column and sort order
    const setDefaultSort = () => {
      setSortColumn("");
      setSortOrder({});
      setParams({});
      setDataFetched(false);
    };

  return { loading, setLoading, page, setPage, total, setTotal, limit, setLimit, totalPages, setTotalPages, params, setParams, sortOrder, setSortOrder, sortColumn, setSortColumn, handleSort, handleLimitChange, dataFetched, setDataFetched, setDefaultSort };
};

export default useApiState;
