import React, { useEffect } from 'react'
import { Spinner, Table } from 'reactstrap'
import { useGetApi } from '../../../Api/useGetApi'
import { API } from '../../../Api/ApiList'
import Sidebar from '../../../Layout/AdminLayout/Sidebar'
import Header from '../../../Layout/AdminLayout/Header'
import LayoutAdmin from '../../../Layout/AdminLayout/Layout'
import { convertToReadableAmount } from '../../../Helper/convertToReadableAmount'
import { dynamicSvg } from '../../../Helper'
import Loader from '../../../Layout/Loader'


export const Dashboard = () => {

  const { data: dataStatics, isLoading } = useGetApi({
    url: API.admin.statics,
    options: { refetchOnWindowFocus: true },
    tokenType: 'admin',
  })

  useEffect(() => {
    console.log('dataStatics', JSON.stringify(dataStatics))
  }, [dataStatics])

  return (

    <LayoutAdmin>
      <div className="page-body">

        {/* <!-- Container-fluid starts--> */}
          <div class="container-fluid ecommerce-dashboard xlt-space xlb-space">
            <div className='card'> 
              <div class="card-header">
                <h3>Dashboard</h3>
              </div>
              {isLoading ? (
          <div className="text-center mt-space mb-space custom-spinner-loader">
            <Loader/>
          </div>
         ) : (  
              <div className='card-body pt-0'>
                <div class="row custom-user-card"> 
                    <div class="col-xl-4 custom-card-revenue col-md-6 ">
                      <div class="card  mb-0">
                        <div class='card-header bg-light-primary'>
                        <div className='custom-card-title'>
                          <svg className="sidebar-toggle">
                            <use href={`${dynamicSvg('admin-sprite.svg')}#stroke-register`}></use>
                          </svg>
                          <h4 class="text-white text-center">Register User</h4>
                        </div>
                        </div>
                        <div class="card-body custom-light-primary">
                          <div class="row ">
                            <div class="col-12">
                              <div class="card">
                                <div class="card-body">
                                  <h5 class="f-w-600">{convertToReadableAmount(dataStatics?.totalCreatorCount)}</h5>
                                  <div class="total-revenue mb-2">
                                    <span>Total Creator Count</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="card mb-0">
                                <div class="card-body">
                                  <h5 class="f-w-600">{convertToReadableAmount(dataStatics?.totalBrandCount)}</h5>
                                  <div class="total-revenue mb-2">
                                    <span>Total Brand Count</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-4 custom-card-revenue col-md-6">
                      <div class="card  mb-0">
                        <div class='card-header bg-light-danger'>
                        <div className='custom-card-title'>
                          <svg className="sidebar-toggle">
                            <use href={`${dynamicSvg('admin-sprite.svg')}#stroke-youtube`}></use>
                          </svg>
                          <h4 class="text-white text-center">YouTube</h4>
                        </div>
                        </div>
                        <div class="card-body custom-light-danger">
                          <div class="row "> 
                            <div class="col-12"> 
                              <div class="card">
                                <div class="card-body">
                                  <h5 class="f-w-600">{convertToReadableAmount(dataStatics?.totalYoutueInfluencerCount)}</h5>
                                  <div class="total-revenue mb-2">
                                    <span>Total Youtube Influencer Count</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="card mb-0">
                                <div class="card-body">
                                  <h5 class="f-w-600">{convertToReadableAmount(dataStatics?.totalYoutuePostInfluencerCount)}</h5>
                                  <div class="total-revenue mb-2">
                                    <span>Total Youtube Influencer Post Count</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-4 custom-card-revenue col-md-6">
                      <div class="card  mb-0">
                        <div className='card-header instagram-bg'>
                          <div className='custom-card-title'>
                            <svg className="sidebar-toggle instagram-icon">
                              <use href={`${dynamicSvg('admin-sprite.svg')}#stroke-instagram`}></use>
                            </svg>
                            <h4 class="text-white text-center">Instagram</h4>
                          </div>
                        </div>
                        <div class="card-body instagram-bg-light">
                          <div class="row ">
                            <div class="col-12">
                              <div class="card">
                                <div class="card-body">
                                  <h5 class="f-w-600"> {(convertToReadableAmount(dataStatics?.totalInstaInfluencerCount))}</h5>
                                  <div class="total-revenue mb-2">
                                    <span>Total Insta Influencer Count</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="card mb-0">
                                <div class="card-body"> 
                                  <h5 class="f-w-600">  {convertToReadableAmount(dataStatics?.totalInstaPostInfluencerCount)}</h5>
                                  <div class="total-revenue mb-2"> 
                                    <span>Total Insta Influencer Post Count</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
             )} 
            </div>
          </div>
       
      </div>
    </LayoutAdmin>


  )
}
