import { ExpandMore } from "@mui/icons-material";
import { Autocomplete, TextField } from "@mui/material";
import React from "react";

const CustomRangeFromTo = ({ value, setValue, filteredFromOptions, filteredToOptions, options }) => {
  return (
    <div className="d-flex flex-row gap-3 p-1">
      <Autocomplete
        className="w-50"
        disablePortal
        popupIcon={<ExpandMore fontSize="small" />}
        options={filteredFromOptions.map((item) => item.title)}
        renderInput={(params) => <TextField {...params} label="From" />}
        value={value.from}
        onChange={(event, newValue) => {
          setValue((prev) => ({
            ...prev,
            from: newValue,
            // Reset "to" value if it is less than the newly selected "from" value
            to: newValue && value.to ? (options.find((opt) => opt.title === value.to)?.value < options.find((opt) => opt.title === newValue)?.value ? null : prev.to) : prev.to,
          }));
        }}
      />
      <Autocomplete
        className="w-50"
        disablePortal
        popupIcon={<ExpandMore fontSize="small" />}
        options={filteredToOptions.map((item) => item.title)}
        renderInput={(params) => <TextField {...params} label="To" />}
        value={value.to}
        onChange={(event, newValue) => {
          setValue((prev) => ({
            ...prev,
            to: newValue,
          }));
        }}
      />
    </div>
  );
};

export default CustomRangeFromTo;
