import React, { useEffect } from "react";
import { Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap";

import { useCreatorFavoriteContext } from "../../../../Context/Creator/CreatorFavoriteContext";
import { CreatorMainList } from "./CreatorMainList";
import { CreatorSubLists } from "./CreatorSubLists";
import { useGetApi } from "../../../../Api/useGetApi";
import { API } from "../../../../Api/ApiList";

export const CreatorListOffcanvas = () => {
  const { setCreatorList, setSingleDelete, setSelectAll, filteredSubList, setSubList, selectedSubLists, setSelectedSubLists, setSelectedLists, setListToDelete, subList, onExitCreatorListCanvas, creatorListModal, toggleCreatorListModal } = useCreatorFavoriteContext();

  const { data, fetchStatus } = useGetApi({
    url: API.creator.getInfluencerFavoriteListApi,
    options: { refetchOnWindowFocus: false },
  });
  const isLoading = fetchStatus === "fetching" || false;

  useEffect(() => {
    if (data !== undefined) {
      setCreatorList(data);
    }
  }, [data, setCreatorList]);

  useEffect(() => {
    if (creatorListModal === true) {
      setListToDelete([]);
      setSelectedLists([]);
      setSelectedSubLists([]);
      setSubList(null);
      setSingleDelete(false);
    }
  }, [creatorListModal, setListToDelete, setSelectAll, setSelectedLists, setSelectedSubLists, setSingleDelete, setSubList]);

  useEffect(() => {
    setSelectAll(filteredSubList?.length === selectedSubLists.length && filteredSubList.length > 0);
  }, [selectedSubLists, filteredSubList, setSelectAll]);

  return (
    <Offcanvas className="creator-list-offcanvas custom-filter-offcanvas" direction="end" isOpen={creatorListModal} toggle={toggleCreatorListModal} onExit={onExitCreatorListCanvas}>
      {isLoading && (
        <div className="loading-overlay d-flex align-items-center justify-content-center gap-2 flex-column">
          <div class="custom-loader"></div>
          <div className="loading-message">Fetching for your favorite lists ...</div>
        </div>
      )} 
      <div className={`list-loader ${isLoading ? "loading" : ""}`}>
        <OffcanvasHeader toggle={toggleCreatorListModal}></OffcanvasHeader>
        <OffcanvasBody>{(subList !== null && subList?.creators?.length > 0) ? <CreatorSubLists /> : <CreatorMainList isLoading={isLoading} />}</OffcanvasBody>
      </div>
    </Offcanvas>
  );
};
