import { Slider } from "@mui/material";
import React, { Fragment, useEffect, useMemo } from "react";
import { Form } from "reactstrap";

import { Btn, Image } from "../../../../AbstractElement";
import { getParams, removeAllFilters, useSetUrlParams } from "../../../../Api/UrlParams";
import { useCreatorContext } from "../../../../Context/Creator/CreatorContext";
import { creatorFollowerOption, platformOption } from "../../../../Data/FindCreatorData";
import { checkActiveRang, dynamicImage } from "../../../../Helper";
import AutocompleteChips from "../../../Common/AutocompleteChips";
import CreatorSelect from "../../../Common/CreatorSelect";
import CustomRangeFromTo from "../../../Common/CustomRangeFromTo";
import InputMultipleAddCommon from "../../../Common/InputMultipleAddCommon";
import SelectWrapper from "../../../Common/SelectWrapper";
import { ContactAndMoreFilter } from "./ContactAndMoreFilter";

export const CreatorFilter = ({ loading, setUrlParamsProcessed, getAndSetParams, setPage, setDefaultSort, topicIsLoading , categoryIsLoading}) => {
  const setParam = useSetUrlParams();
  const { followerRange, setFollowerRange, filteredToOptions, filteredFromOptions, setChannelTopicList, setChannelTopicParam, channelTopicParam, channelTopicOption, channelTopicList, removeUrlParams, platFormSelectedItem, setPlatFormSelectedItem, hashTagInputValues, setHashTagInputValues, hashtags, setHashtags, engagementValue, setEngagementValue, categoryMention, setCategoryMention, categoryInputValue, setCategoryInputValue,categoryOption } = useCreatorContext();
  const { channelTopic, platform, engagementRate, category ,creatorMinFollower, creatorMaxFollower, hashtags: urlHashtags } = getParams();

  const isInstagram = platFormSelectedItem?.title === "instagram";
  const label = isInstagram ? "Followers" : "Subscribers";

  // Disable the button if no filters are active
  const isDisabled = useMemo(() => {
    return !!platFormSelectedItem?.title && (checkActiveRang(followerRange) || categoryMention.length > 0 || hashtags.length > 0 || engagementValue > 0 || channelTopicList.length > 0);
  }, [channelTopicList.length, engagementValue, followerRange, hashtags.length, platFormSelectedItem?.title, categoryMention.length]);

  useEffect(() => {
    const paramHashtags = urlHashtags ? urlHashtags.split(",").map((item) => item.trim()) : [];
    setHashtags(paramHashtags.length ? [...new Set(paramHashtags)] : []);
  }, [urlHashtags, setHashtags]);

  useEffect(() => {
    if (engagementRate) setEngagementValue(Number(engagementRate));
    else setEngagementValue(0);
  }, [engagementRate, setEngagementValue]);

  useEffect(() => {
    if (platform) {
      const selectedPlatform = platformOption.find((item) => item.title === platform);
      setPlatFormSelectedItem(selectedPlatform);
    } else {
      setPlatFormSelectedItem(null);
    }
  }, [platform, setPlatFormSelectedItem]);

  // Extract the platform from the URL or set default
  useEffect(() => {
    const platformFromUrl = platform;

    if (platformFromUrl) {
      const selectedPlatform = platformOption.find((item) => item.title === platformFromUrl);
      setPlatFormSelectedItem(selectedPlatform);
    } else {
      // Default to "instagram" if no platform is selected
      const defaultPlatform = platformOption.find((item) => item.title === "instagram");
      setPlatFormSelectedItem(defaultPlatform);
    }
  }, [platform, setPlatFormSelectedItem]);

  useEffect(() => {
    if (channelTopic && channelTopic.length) {
      const channelTopicsArray = channelTopic.split(",");
      setChannelTopicList(channelTopicsArray);
    }
  }, [channelTopic, setChannelTopicList]);

  // Set values based on URL params for followers
  useEffect(() => {
    const getFollowerTitle = (value) => creatorFollowerOption.find((item) => item.value === Number(value))?.title || null;
    const minFollowerTitle = getFollowerTitle(creatorMinFollower);
    const maxFollowerTitle = getFollowerTitle(creatorMaxFollower);

    // Update state only if at least one title is found
    if (minFollowerTitle || maxFollowerTitle) setFollowerRange({ from: minFollowerTitle, to: maxFollowerTitle });
  }, [creatorMaxFollower, creatorMinFollower, setFollowerRange]);

  const submitSearch = (e) => {
    e.preventDefault();
  
    const formattedHashTag = hashtags
      .join(",")
      .split(",")
      .map((tag) => tag.replace(/#/g, ""))
      .join(",");
  
    console.log("🚀 ~ submitSearch ~ categoryMention:", categoryMention);
  
    // Build parameters with length/existence checks
    const params = {
      platform: platFormSelectedItem?.title || null,
      hashtags: formattedHashTag.length ? formattedHashTag : null,
      category: categoryMention.length ? categoryMention : null,
      creatorMinFollower: followerRange.from !== null 
        ? creatorFollowerOption.find((item) => item.title === followerRange.from)?.value 
        : null,
      creatorMaxFollower: followerRange.to !== null 
        ? creatorFollowerOption.find((item) => item.title === followerRange.to)?.value 
        : null,
      engagementRate: engagementValue > 0 ? engagementValue : null,
      channelTopic: channelTopicList.length ? channelTopicList : null,
    };
  
    // Filter out null values from the params object
    console.log("🚀 ~ submitSearch ~ params:", params)
    const filteredParams = Object.fromEntries(
      Object.entries(params).filter(([_, value]) => {
        return value !== null
      })
    );
  
    // Update the URL parameters
    setParam(filteredParams);
  
    setUrlParamsProcessed(true);
    getAndSetParams();
    setPage(1);
  };

  const handlePlatformChange = () => {
    setDefaultSort();
    removeAllFilters("platform");
    removeUrlParams();
  };

  return (
    <div className="top-filter-panel mb-5">
      <Form className="filter-form-box" onSubmit={submitSearch}>
        <CreatorSelect options={platformOption} selectedItem={platFormSelectedItem} setSelectedItem={setPlatFormSelectedItem} setParam={setParam} handlePlatformChange={handlePlatformChange} />

        <SelectWrapper title={label} selected={checkActiveRang(followerRange)}>
          <CustomRangeFromTo value={followerRange} setValue={setFollowerRange} filteredFromOptions={filteredFromOptions} filteredToOptions={filteredToOptions} options={creatorFollowerOption} />
        </SelectWrapper>

        {platFormSelectedItem?.title === "instagram" && (
          <Fragment>
            <SelectWrapper title="Category" selected={categoryMention.length > 0} taglNews={true}>
              <AutocompleteChips options={categoryOption} value={categoryMention} setValue={setCategoryMention} inputValue={categoryInputValue} setInputValue={setCategoryInputValue} placeholder="Search categories ..." isLoading={categoryIsLoading} />
            </SelectWrapper>
          </Fragment>
        )}

        {platFormSelectedItem?.title === "instagram" && (
          <Fragment>
            <SelectWrapper title="Hashtags" selected={hashtags.length > 0}>
              <InputMultipleAddCommon title="Hashtags" inputValue={hashTagInputValues} setInputValue={setHashTagInputValues} items={hashtags} setItems={setHashtags} multiple={true} />
            </SelectWrapper>
          </Fragment>
        )}


        {platFormSelectedItem?.title === "youtube" && (
          <Fragment>
            <SelectWrapper title="Topics" toolTip="Filter creators by subscribers count." selected={channelTopicList.length}>
              <AutocompleteChips options={channelTopicOption} value={channelTopicList} setValue={setChannelTopicList} inputValue={channelTopicParam} setInputValue={setChannelTopicParam} placeholder="Search channel topics ..." isLoading={topicIsLoading} />
            </SelectWrapper>
          </Fragment>
        )}

        <SelectWrapper title="Engagement Rate" selected={engagementValue > 0}>
          <div className="select-inner-box">
            <p>More than <strong>{engagementValue}%</strong></p>
            <div className="px-2">
              <Slider value={engagementValue} min={0} max={100} step={1} onChange={(e, newValue) => setEngagementValue(newValue)} />
            </div>
          </div>
        </SelectWrapper>

        <Btn className="btn-theme search-btn" type="submit" disabled={loading || !isDisabled}>
          <Image src={dynamicImage("svg/search.svg")} className="search-icon" alt="Search Icon" /> Search
        </Btn>
      </Form>
      <ContactAndMoreFilter />
    </div>
  );
};
