import React, { useEffect, useState } from 'react'
import { useGetApi } from '../../../Api/useGetApi'
import { API } from '../../../Api/ApiList'
import LayoutAdmin from '../../../Layout/AdminLayout/Layout'
import DataTable from '../Common/DataTable'
import { Spinner } from 'reactstrap'
import Loader from '../../../Layout/Loader'


export const DBDetailsContainer = () => {
  // State to track the search input value
  const [searchQuery, setSearchQuery] = useState('');

  const columns = [
    { field: 'influencer_user.name', headerName: 'Name' },
    { field: 'influencer_user.email', headerName: 'Email' },
    { field: 'influencer_user.account_type', headerName: 'Account Type' },
    { field: 'ip_address', headerName: 'IP Address' },
    { field: 'login_time', headerName: 'Login Time' },
    // { field: 'user_agent', headerName: 'User Agent' },
  ];


  const { data, isLoading, error } = useGetApi({
    url: API.admin.loginActivity,
    params: { limit: 200 },
    tokenType: 'admin',
  })

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Filter the data based on the search input (filtering on name or email)
  const filteredData = data?.data?.filter((data) => {
    const search = searchQuery.toLowerCase();
    return (
      data?.influencer_user?.name?.toLowerCase().includes(search) ||
      data?.influencer_user?.email?.toLowerCase().includes(search) ||
      data?.influencer_user?.account_type?.toLowerCase().includes(search)
    );
  });  

  return (
    <>
      <LayoutAdmin>
        <div className="page-body">
          <div className="container-fluid ecommerce-dashboard xlb-space xlt-space">
            <div className="row"> 
              <div class="col-12">
                <div class="card custom-activity-table">
                  <div class="card-header">
                    <h3>Login Activity List</h3>
                    <div className="align-items-center justify-content-end custom-product-order row">
                        <div className="col-auto">
                          <label className="form-label">Search:</label>
                        </div>
                        <div className="d-flex flex-row col-auto p-0">
                          {/* Search Input */}
                          <input
                            type="text"
                            className="form-control"
                            value={searchQuery}
                            onChange={handleSearchChange} // Handle search input change
                            placeholder="Search by Name or Email"
                          />
                        </div>
                    </div>
                  </div>
                  <div className="card-body product-order pt-0">
                    {/* Conditional Rendering for Loading/Errors */}
                    {isLoading ? ( 
                      <div className="text-center mt-space mb-space custom-spinner-loader">
                        <Loader /> {/* Show a spinner while loading */}
                      </div>
                    ) : error ? (
                      <div className="text-danger text-center">
                        <p>Error fetching brand data: {error.message}</p>
                      </div>
                    ) : filteredData?.length === 0 ? (
                      <div className="text-center">
                        <p>No login activity  match your search.</p>
                      </div>
                    ) : (
                      <div className="table-responsive custom-scrollbar">
                        <DataTable columns={columns} data={filteredData} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutAdmin>
    </>

  )
}
