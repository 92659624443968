export const Category = [
'Architecture & Design',
'Animal Welfare & Pets',
'Professional Services',
'Finance & Business',
'Religion & Spirituality',
'Nonprofit & Social Causes',
'Luxury & Fashion',
'Family & Parenting',
'Agriculture & Farming',
'Film & Video Production',
'Real Estate & Property',
'Politics & Government',
'Photography & Visual Arts',
'Entertainment & Media',
'Healthcare & Medical',
'Food & Beverage',
'Gaming & eSports',
'Marketing & Advertising',
'Dance & Performance',
'Health & Wellness',
'Weddings & Events',
'Media & Journalism',
'Sports & Fitness',
'Literature & Writing',
'Nature & Wildlife',
'Shopping & Retail',
'Military & Defense',
'Technology & Computing',
'Adventure & Outdoor Sports',
'Music & Audio',
'Hospitality & Tourism',
'History & Culture',
'Arts & Creativity',
'Automotive & Transportation',
'Education & Academia',
'Lifestyle & Wellness',
]