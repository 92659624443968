import React, { Fragment } from "react";

import FAQSection from "./FAQSection";
// import BlogSection from "./BlogSection";
// import BrandSection from "./BrandSection";
import BannerSection from "./BannerSection";
import ServiceSection from "./ServiceSection";
import CreatorsSection from "./CreatorsSection";
// import TopBrandSection from "./TopBrandSection";
import InfluencerSection from "./InfluencerSection";
import AiPoweredSection from "./AiPoweredSection";
// import TestimonialSection from "./TestimonialSection";

const HomeContainer = () => {
  return (
    <Fragment>
      <BannerSection />
      {/* <BrandSection /> */}
      <AiPoweredSection />
      <ServiceSection />
      <CreatorsSection />
      {/* <TopBrandSection /> */}
      {/* <InfluencerSection /> */}
      <FAQSection />
      {/* <TestimonialSection /> */}
      {/* <BlogSection /> */}
    </Fragment>
  );
};

export default HomeContainer;
