import React, { useEffect } from "react";

import { CreatorFilter } from "./CreatorFilter";
import CreatorAiFilter from "./CreatorAiFilter";
import { AddedFilterList } from "./AddedFilterList";
import { useCreatorContext } from "../../../../Context/Creator/CreatorContext";
import { getParams } from "../../../../Api/UrlParams";

export const CreatorsFilters = ({ loading, setParams, setUrlParamsProcessed, getAndSetParams, setPage, setLimit, setDataFetched, setDefaultSort, topicIsLoading, categoryIsLoading }) => {
  const getParam = getParams();

  const { setVideoDuration, setPostKey, setMentionsTags, setBioKey, setCreatorVerify, setRecentPosts } = useCreatorContext();
  const { videoDurations, postKeyWord, biokeyword, isVerify, mostRecentPosts, mentions: urlMentions } = getParam;

  useEffect(() => {
    const paramMentions = urlMentions ? urlMentions.split(",").map((item) => item.trim()) : [];
    setMentionsTags(paramMentions.length ? [...new Set(paramMentions)] : []);
  }, [urlMentions, setMentionsTags]);

  useEffect(() => {
    setCreatorVerify(isVerify);
    setRecentPosts(mostRecentPosts);
    setBioKey(biokeyword);
    setPostKey(postKeyWord);
    setVideoDuration(videoDurations);
  }, [biokeyword, isVerify, mostRecentPosts, postKeyWord, setBioKey, setCreatorVerify, setPostKey, setRecentPosts, setVideoDuration, videoDurations]);

  return (
    <div className="sb-space">
      <CreatorFilter topicIsLoading={topicIsLoading} categoryIsLoading={categoryIsLoading} loading={loading} setUrlParamsProcessed={setUrlParamsProcessed} getAndSetParams={getAndSetParams} setPage={setPage} setDefaultSort={setDefaultSort} />
      <CreatorAiFilter setParams={setParams} loading={loading} setUrlParamsProcessed={setUrlParamsProcessed} setLimit={setLimit} setPage={setPage} />
      <AddedFilterList setDataFetched={setDataFetched} getAndSetParams={getAndSetParams} loading={loading} setDefaultSort={setDefaultSort} />
    </div>
  );
};
