import { createContext, useCallback, useContext, useState } from "react";

const LayoutContext = createContext();

// Define the initial state for the context
const initialState = {
  responsiveMenu:false,
  toggleSidebar: false,
};

export const LayoutContextProvider = ({ children }) => {
  const [layoutState, setLayoutState] = useState(initialState);

    // Update the state object
    const updateState = useCallback((newState) => {
      setLayoutState((prevState) => ({ ...prevState, ...newState }));
    }, []);

  return <LayoutContext.Provider value={{ layoutState, updateState }}>{children}</LayoutContext.Provider>;
};

export const useLayoutContext = () => {
  return useContext(LayoutContext);
};
