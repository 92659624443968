import React from "react";
import { Link, useLocation } from "react-router-dom";
import { dynamicSvg } from "../../../Helper";

const SidebarItem = ({ iconId, title, to }) => {
  const location = useLocation();
  return (
    <li className={`sidebar-list ${location.pathname === to ? "active" : ""}`}>
      <i className="fa fa-thumb-tack"></i>
      <Link className="sidebar-link sidebar-title" to={to}>
        <svg className="stroke-icon">
          <use href={`${dynamicSvg('admin-sprite.svg')}#${iconId}`}></use>
        </svg>
        <svg className="fill-icon">
          <use href={`${dynamicSvg('admin-sprite.svg')}#${iconId}`}></use>
        </svg>
        <span className="lan-3">{title}</span>
      </Link>
    </li>
  );
}

export default SidebarItem;
