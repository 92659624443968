import React, { useState } from "react";
import { Button, ClickAwayListener } from "@mui/material";
import { Check, ExpandLess, ExpandMore } from "@mui/icons-material";

const SelectWrapper = ({ children, title, selected = false , taglNews=false}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className={`select-wrapper ${selected ? "selected" : ""}`}>
      <div onClick={handleOpen} className="select-btn">
        <Button variant="outlined" size="large" className={open ? "open" : ""} endIcon={selected ? <Check className="check-icon" /> : open ? <ExpandLess /> : <ExpandMore />}>
          {title}  {taglNews && <span className="taglNews">New</span>}
        </Button>
      </div>
      {open && (
        <ClickAwayListener onClickAway={handleClose}>
          <div className="select-child">
            <div className="select-inner">{children}</div>
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default SelectWrapper;
