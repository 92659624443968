import { Skeleton } from "@mui/material";
import { Fragment } from "react";

export const TableSkeleton = ({ loop }) => (
  <div className="skeleton-wrapper">
    <div className="d-flex align-items-center gap-3 px-3" spacing={1}>
      <Skeleton variant="square" className="rounded-2" width={24} height={24} />
      <Skeleton variant="rectangular" width="100%" className="rounded-2" height={35} />
    </div>
    {[...Array(loop)].map((_, index) => (
      <div key={index} className="d-flex align-items-center gap-3 px-3" spacing={1}>
        <Skeleton variant="square" className="rounded-2" width={24} height={24} />
        <Skeleton variant="circular" width={50} height={46} />
        <Skeleton variant="rectangular" width="100%" className="rounded-2" height={35} />
      </div>
    ))}
  </div>
);

export const ListSkeleton = ({ loop }) => (
  <div className="skeleton-wrapper">
    <Skeleton variant="rectangular" width="50%" className="rounded-2 mb-3" height={40} />
    <Skeleton variant="rectangular" width="100%" className="rounded-2 mb-3" height={40} />
    {[...Array(loop)].map((_, index) => (
      <div key={index} className="d-flex align-items-center gap-3 px-3 mb-3" spacing={1}>
        <Skeleton variant="square" className="rounded-2" width={24} height={24} />
        <Skeleton variant="circular" width={50} height={44} />
        <Skeleton variant="rectangular" width="100%" className="rounded-2" height={40} />
      </div>
    ))}
  </div>
);

export const FilterListSkeleton = ({ loop }) => (
  <Fragment>
    {[...Array(loop)].map((_, index) => (
      <div key={index} className="skeleton-wrapper">
        <Skeleton variant="rectangular" width="100%" className="rounded-2 mb-3" height={10} />
      </div>
    ))}
  </Fragment>
);
