import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import React, { Fragment, useEffect, useState } from "react";

import { ROUTES } from "../../../Routes/Routes";
import getStorage, { KEYS } from "../../../Helper/getStorage";
import ForgetPasswordContainer from "../../../Component/Auth/ForgetPassword";

const ForgetPassword = () => {
  const { getItem, removeItem } = getStorage()
  const [forgetVerified, setForgetVerified] = useState(getItem(KEYS.FORGET));
  const navigate = useNavigate();

  useEffect(() => {
    if (forgetVerified !== null && forgetVerified) {
      setForgetVerified(true);
    } else {
      navigate(ROUTES.Auth.Login);
      toast.error("Page Not Found !!!");
    }

    return () => {
      removeItem(KEYS.FORGET);
    };
  }, [forgetVerified, navigate, removeItem]);

  return <Fragment>{forgetVerified && <ForgetPasswordContainer />}</Fragment>;
};

export default ForgetPassword;
