import React, { Fragment } from "react";
import { Outlet } from "react-router-dom";

import Header from "./Header";
import Sidebar from "./Sidebar";


const LayoutAdmin = ({ children }) => {
    return (
        <Fragment>
            <div className="page-wrapper compact-wrapper " id="pageWrapper">
                <Header />
                <div className="page-body-wrapper">
                    <Sidebar />
                    {children}
                </div>
            </div>
        </Fragment>
    );
};

export default LayoutAdmin;
