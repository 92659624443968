import React, { Fragment } from "react";

import { dynamicImage } from "../../../Helper";
import { Btn, Image } from "../../../AbstractElement";
import ExportModal from "../../FindCreator/Modals/ExportModal";
import { useCreatorContext } from "../../../Context/Creator/CreatorContext";

const ExportCsvData = ({ params = {}, total, tableId }) => {
  const { exportModal, toggleExportCsv } = useCreatorContext();

  return (
    <Fragment>
      <Btn onClick={toggleExportCsv} className="more-filter-btn">
        <Image className="img-fluid" src={dynamicImage("svg/export.svg")} /> Export CSV
      </Btn>

      {exportModal && <ExportModal total={total} params={params} tableId={tableId} />}
    </Fragment>
  );
};

export default ExportCsvData;
