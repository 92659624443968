import React from "react";
import { Link } from "react-router-dom";
import { dynamicImage } from "../../../Helper";
import { Image } from "../../../AbstractElement";

const Logo = ({ src, alt, to }) => (
  <Link to={to}>
    <Image className="logo" src={dynamicImage(src)} alt={alt} />
  </Link>
);

export default Logo;
