import React, { useState } from "react";
import { Card, CardBody, CardText, CardTitle, Col, Row, Table } from "reactstrap";
import { useGetApi } from "../../../Api/useGetApi";
import { API } from "../../../Api/ApiList";
import LayoutAdmin from "../../../Layout/AdminLayout/Layout";
import DataTable from "../Common/DataTable";
import { Spinner } from "reactstrap"; // Assuming you have some spinner component
import Loader from "../../../Layout/Loader";

export const BrandContainer = () => {
  // State to track the search input value
  const [searchQuery, setSearchQuery] = useState('');

  const columns = [
    { field: 'name', headerName: 'Name' },
    { field: 'email', headerName: 'Email' },
    { field: 'unique_id', headerName: 'Unique Id' },
    { field: 'status', headerName: 'Status' },
    { field: 'last_login_time', headerName: 'Last Login' },
    { field: 'created_at', headerName: 'Created At' },
   
  ];

  // Fetching the brand data using custom hook
  const { data, isLoading, error } = useGetApi({
    url: API.admin.brandList,
    params: {},
    tokenType: "admin",
  });

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };  

  // Filter the data based on the search input (filtering on name or email)
  const filteredData = data?.brands?.filter((brand) => {
    const search = searchQuery.toLowerCase();
    return (
      brand.name?.toLowerCase().includes(search) ||
      brand.email?.toLowerCase().includes(search)
    );
  });

  return (
    <LayoutAdmin>
      <div className="page-body">
          <div className="container-fluid ecommerce-dashboard xlt-space xlb-space">
            <div className="row"> 
              <div class="col-12">
                <div class="card custom-activity-table">
                  <div class="card-header">
                    <h3>Brnad List</h3>
                    <div className="align-items-center justify-content-end custom-product-order row">
                        <div className="col-auto">
                          <label className="form-label">Search:</label>
                        </div>
                        <div className="d-flex flex-row col-auto p-0">
                          {/* Search Input */}
                          <input
                            type="text"
                            className="form-control"
                            value={searchQuery}
                            onChange={handleSearchChange} // Handle search input change
                            placeholder="Search..."
                          />
                        </div>
                    </div>
                  </div>
                  <div className="card-body product-order custom-list-table pt-0">
                    {/* Conditional Rendering for Loading/Errors */}
                    {isLoading ? ( 
                      <div className="text-center mt-space mb-space custom-spinner-loader">
                        <Loader /> {/* Show a spinner while loading */}
                      </div>
                    ) : error ? (
                      <div className="text-danger text-center">
                        <p>Error fetching brand data: {error.message}</p>
                      </div>
                    ) : filteredData?.length === 0 ? (
                      <div className="text-center">
                        <p>No login activity  match your search.</p>
                      </div>
                    ) : (
                      <div className="table-responsive custom-scrollbar">
                        <DataTable className="custom-scrollbar" columns={columns} data={filteredData} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </LayoutAdmin>
  );
};
