import { createContext, useContext, useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import * as Yup from "yup";

import getStorage, { KEYS } from "../../Helper/getStorage";
import { API } from "../../Api/ApiList";
import { ROUTES } from "../../Routes/Routes";

export const AdminAuthContext = createContext();

export const AdminAuthContextProvider = ({ children }) => {
  const { getItem, setItem, clearItems } = getStorage();

  const [isAdminAuthenticated, setIsAdminAuthenticated] = useState(() => {
    return getItem(KEYS.ADMIN_UAT) !== null;
  });

  const AdminLoginSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is a required"),
    password: Yup.string().required("Password is a required"),
  });

  // Admin Login
  const handleAdminLogin = async ({ data, navigate, setLoading }) => {
    setLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/${API.admin.adminLoginApi}`, { email: data?.email, password: data?.password });
      if (response.status === 201 || response.status === 200) {
        const userData = response?.data?.data;

        setItem(KEYS.ADMIN_UAT, JSON.stringify(userData));
        setIsAdminAuthenticated(true);

        const validToken = getItem(KEYS.ADMIN_UAT);
        if (validToken) {
          navigate(ROUTES.Admin.dbDetails);
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const handleAdminSignOut = (navigate) => {
    clearItems();
    setIsAdminAuthenticated(false);
    navigate && navigate(ROUTES.Admin.AdminLogin);
  };

  return <AdminAuthContext.Provider value={{ isAdminAuthenticated, handleAdminLogin, handleAdminSignOut, AdminLoginSchema }}>{children}</AdminAuthContext.Provider>;
};

export const useAdminAuthContext = () => {
  return useContext(AdminAuthContext);
};
