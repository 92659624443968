import { useMutation } from "@tanstack/react-query";

import { axiosInstance, useErrorMessageHandler } from "./middleware";

export const usePostApi = ({ url, data, options, onSuccess }) => {
  const { handleError } = useErrorMessageHandler();

  const mutation = useMutation({
    mutationFn: async () => {
      try {
        const response = await axiosInstance.post(url, data);
        return response.data;
      } catch (error) {
        handleError(error);
      }
    },
    onSuccess: (data) => {
      if (onSuccess) {
        onSuccess(data);
      }
    },
    ...options,
  });

  return mutation;
};
