import axios from "axios";

import getStorage, { KEYS } from "../Helper/getStorage";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../Context/Auth/AuthContext";
import { useAdminAuthContext } from "../Context/Auth/AdminAuthContext";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

const checkTokenAndNavigate = (tokenType) => {
  const { getItem } = getStorage();

  // Determine which token to check based on tokenType
  const key = tokenType === "admin" ? KEYS.ADMIN_UAT : KEYS.UAT;
  const { token } = JSON.parse(getItem(key));

  if (!token) {
    return false; // Token is missing
  }
  return true; // Token is present
};

// Middleware to set Bearer token from local storage based on tokenType
axiosInstance.interceptors.request.use(
  (config) => {
    const tokenExists = checkTokenAndNavigate(config.tokenType); // Pass tokenType
    if (tokenExists) {
      const { getItem } = getStorage();

      // Use different token keys based on the tokenType in config
      const key = config.tokenType === "admin" ? KEYS.ADMIN_UAT : KEYS.UAT;
      const { token } = JSON.parse(getItem(key));

      config.headers["x-access-token"] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const useErrorMessageHandler = () => {
  const navigate = useNavigate();
  const { handleSignOut } = useAuthContext();
  const { handleAdminSignOut } = useAdminAuthContext();

  const handleError = (error, tokenType) => {
    // Check if error status is 401 (unauthorized) and handle sign-out
    if (error.response?.status === 401) {
      toast.error("Session expired. Please log in again.");
      if (tokenType === "admin") {
        handleAdminSignOut(navigate);
      } else {
        handleSignOut(navigate); // Perform sign-out
      }
    } else {
      // For other errors, show a general error message
      toast.error(error?.response?.data?.message || error?.response?.data?.error || "An error occurred.");
    }

    throw error; // Ensure the error is rethrown
  };

  return { handleError };
};
