import React, { Fragment } from "react";
import { Button } from "reactstrap";

export const Btn = (props) => {
  const { children, loading } = props;

  return (
    <Button {...props} color={props.color ? props.color : "transparent"} className={`${loading ? "loader" : ""} ${props.className ? props.className : ""}`}>
      {loading ? (
        <Fragment>
          <span className="me-1">{children}</span>
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="animate-spin h-full">
            <path d="M12 21a9 9 0 1 1 0 -18a9 9 0 0 1 0 18z"></path>
            <path d="M18 12a6 6 0 0 1 -6 6"></path>
          </svg>
        </Fragment>
      ) : (
        children
      )}
    </Button>
  );
};
