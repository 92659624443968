import React from "react";
import { Image } from "../../../AbstractElement";
import { dynamicImage } from "../../../Helper";

export const DefaultContent = ({ image, title, subTitle, imgClass, mainClass }) => {
  return (
    <div className={`no-filter-content ${mainClass ? mainClass : ""}`}>
      <Image className={`img-fluid ${imgClass ? imgClass : ""}`} src={dynamicImage(`svg/vector/${image}.svg`)} />
      <div className="filter-content">
        <h4>{title}</h4>
        <h6>{subTitle}</h6>
      </div>
    </div>
  );
};
