import React, { Fragment } from "react";

import { dynamicImage } from "../../../Helper";
import { Image } from "../../../AbstractElement";

const PassStrength = ({ passwordStrength }) => {
  return (
    <Fragment>
      <ul className={`password-indicate ${passwordStrength}`}>
        <li></li>
        <li></li>
        <li></li>
      </ul>
      <div className="password-indicate-note">
        <div className="indicate">
          <span className="text-capitalize">{passwordStrength}</span>
          <div className="more-info">
            <Image src={dynamicImage("svg/info-circle.svg")} />
            <div className="info">
              <div className="info-icon">
                <Image src={dynamicImage("svg/info-circle.svg")} />
              </div>
              <p>Minimum of 8 characters, including 1 lowercase, 1 capital, and 1 symbol.</p>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PassStrength;
