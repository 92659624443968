// src/components/Sidebar/Sidebar.js
import React, { useEffect, useState } from "react";
import {  dynamicImage, dynamicSvg } from "../../../Helper";
import { ROUTES } from "../../../Routes/Routes";
import SidebarItem from "./SidebarItem";
import Logo from "./Logo";
import { Image } from "../../../AbstractElement";

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(true);
  // Sidebar items data
  const navItems = [
    { icon: 'stroke-home', title: 'Dashboard', to: ROUTES.Admin.dashboard },
    { icon: 'stroke-blog', title: 'Brand List', to: ROUTES.Admin.brandDetails },
    { icon: 'stroke-others', title: 'Login List', to: ROUTES.Admin.dbDetails }
  ];

  const toggleClick = () => {
    document.querySelector(".page-header")?.classList.toggle("close_icon");
    document.querySelector(".sidebar-wrapper")?.classList.toggle("close_icon");
    setIsOpen(!isOpen);
  };
  
  useEffect(() => {
    const compactSidebar = () => {
      const windowWidth = window.innerWidth;
      const action = windowWidth < 1200 ? 'add' : 'remove';
      document.querySelector(".page-header")?.classList[action]("close_icon");
      document.querySelector(".sidebar-wrapper")?.classList[action]("close_icon");
      const activeSidebar = windowWidth < 992 ? 'add' : 'remove';
      document.querySelector(".page-header")?.classList[activeSidebar]("close_icon");
      document.querySelector(".sidebar-wrapper")?.classList[activeSidebar]("close_icon");
    };
  
    compactSidebar();
    window.addEventListener("resize", compactSidebar);
  
    return () => window.removeEventListener("resize", compactSidebar);
  }, []);
   

  return (
    <div className="sidebar-wrapper " data-layout="stroke-svg">
      <div>
        <div className="logo-wrapper">
          <Logo src="logo.svg" alt="Logo" to={ROUTES.Home} />
          <div className="toggle-sidebar" onClick={toggleClick}>
            <svg className="sidebar-toggle">
              <use href={`${dynamicSvg('admin-sprite.svg')}#toggle-icon`}></use>
            </svg>
          </div>
        </div>
        <div className="logo-icon-wrapper">
        <Image className="img-fluid" alt="Logo Icon" to="index.html" src={dynamicImage("favicon-logo.svg")}/>
          {/* <Logo src={"logo192.png"} alt="Logo Icon" to="index.html" /> */}
        </div>
        <nav className="sidebar-main">
          <div className="left-arrow" id="left-arrow">
            <i data-feather="arrow-left"></i>
          </div>
          <div id="sidebar-menu">
            <ul className="sidebar-links custom-scrollbar" id="simple-bar">
              <li className="back-btn">
                <div className="d-flex align-items-center gap-2">
                  <Logo src="logo.svg" alt="Logo" to="index.html" />
                  <div className="mobile-back text-end">
                    <span>Back</span>
                    <i className="fa fa-angle-right ps-2" aria-hidden="true"></i>
                  </div>
                </div>
              </li>
              {navItems.map((item, index) => (
                <SidebarItem
                  key={index}
                  iconId={item.icon}
                  title={item.title}
                  to={item.to}
                />
              ))}
            </ul>
          </div>
          <div className="right-arrow" id="right-arrow">
            <i data-feather="arrow-right"></i>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;