import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import { dynamicImage } from "../../../Helper";
import { Btn, Image } from "../../../AbstractElement";
import { useCreatorFavoriteContext } from "../../../Context/Creator/CreatorFavoriteContext";
import { useDeleteApi } from "../../../Api/useDeleteApi";
import { API } from "../../../Api/ApiList";
import toast from "react-hot-toast";

export const DeleteListModal = () => {
  const { setSelectedLists, listToDelete, deleteTag, subList, selectedSubLists, selectedLists, deleteListModal, toggleDeleteList } = useCreatorFavoriteContext();

  const totalSelected = selectedLists.length || selectedSubLists.length || 1;
  const isMultiple = totalSelected > 1;
  const tag = selectedLists.length > 0 ? "list" : selectedSubLists.length > 0 ? "creator" : deleteTag;

  // Delete list from favorite
  const { mutate:favoriteListMutate, isPending: favoriteListLoading } = useDeleteApi({
    url: API.creator.deleteInfluencerFavoriteListApi,
    onSuccess: () => {
      toast.success("Favorite list deleted successfully");
      toggleDeleteList();
      setSelectedLists([]);
    },
  });

  // Delete list from sub list
  const { mutate:influencerMutate, isPending: influencerLoading } = useDeleteApi({
    url: API.creator.deleteInfluencerFromListApi,
    onSuccess: () => {
      toast.success("Influencer deleted successfully");
      toggleDeleteList();
    },
  });

  const deleteHandler = () => {
    if (selectedSubLists.length > 0 && subList !== null) {
      influencerMutate({favorite_list_id: subList?.id,influencer_ids: selectedSubLists,});
    } else {
      favoriteListMutate({favorite_list_ids: listToDelete})
    }
  };

  return (
    <Modal className="creator-addlist" centered isOpen={deleteListModal} toggle={toggleDeleteList}>
      <ModalHeader toggle={toggleDeleteList} className="border-0" />
      <ModalBody>
        <div className="list-wrap">
          <div className="delete-icon"><Image src={dynamicImage("svg/info.svg")} alt="info icon" /></div>
          <div className="list-detail">
            <h5>Delete {totalSelected} {tag}{isMultiple && "s"}?</h5>
            <p>You’re about to permanently delete {totalSelected} {tag}{isMultiple && "s"}. This action can’t be reversed.</p>
          </div>
        </div>
        <div className="d-flex mt-2 gap-3">
          <Btn color="light" className="w-100" onClick={toggleDeleteList}>Cancel</Btn>
          <Btn className="w-100 btn-danger" disabled={favoriteListLoading || influencerLoading} onClick={deleteHandler} loading={favoriteListLoading || influencerLoading}>
            <Image src={dynamicImage("svg/delete.svg")} alt="delete icon" /> Delete
          </Btn>
        </div>
      </ModalBody>
    </Modal>
  );
};
