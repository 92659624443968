import { useEffect, useRef } from "react";  

export const useAutoFocus = (isOpen) => {  
  const inputRef = useRef(null);  

  useEffect(() => {  
    const timeout = setTimeout(() => {  
      if (isOpen && inputRef.current) {  
        inputRef.current.focus();  
      }  
    }, 100); // Small delay to ensure the component is fully rendered  

    return () => clearTimeout(timeout); // Clean up timeout on unmount or on next render  
  }, [isOpen]);  

  return inputRef;  
};