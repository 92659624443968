import React from "react";
import { Col, Row } from "reactstrap";
import { Link } from "react-router-dom";

import LoginForm from "./LoginForm";
import { dynamicImage } from "../../../Helper";
import { ROUTES } from "../../../Routes/Routes";
import { Image } from "../../../AbstractElement";

const LoginContainer = () => {
  // const { handleInstagramLogin } = useAuthContext();

  return (
    <section className="auth-section">
      <Row className="auth-main g-0">
        <Col lg={7} xs={12}>
          <div className="auth-form">
            <div>
              <Link to={ROUTES.Home}>
                <Image className="logo" src={dynamicImage("logo.svg")} />
              </Link>
              <h2>Welcome Back!</h2>
              <h6>Please Log In to Your Account</h6>
              <LoginForm />
              <div className="divider">
                <span>or</span>
              </div>
              {/* <div className="social-media">
                <Btn onClick={handleInstagramLogin} className="insta-btn fw-normal">
                  <Image src={dynamicImage("svg/insta.svg")} />
                  Continue with Instagram
                </Btn>
                <Btn className="insta-btn fw-normal">
                  <Image src={dynamicImage("svg/youtube.svg")} />
                  Continue with YouTube
                </Btn>
              </div> */}
              <div className="create-wrap">
                <h6>Don’t have an Account?</h6>
                <Link to={ROUTES.Auth.Signup}>Create Now</Link>
              </div>
            </div>
          </div>
        </Col>
        <Col lg={5} className="d-lg-inline-block d-none">
          <div className="image-main">
            <div className="image-contain"></div>
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default LoginContainer;
