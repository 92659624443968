import Papa from "papaparse";
import toast from "react-hot-toast";

import { formatInstagramNumber } from "../Helper";
import { countriesList } from "../Data/CountryData";
import { videoLanguagesOptions } from "../Data/VideoFilterData";

// Function to format date to mm/dd/yyyy h:m:s
const formatDate = (date) => {
  const options = { year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit", second: "2-digit", hour12: false };

  const dateTimeString = date.toLocaleString(undefined, options);
  const [month, day, yearTime] = dateTimeString.split("/");
  const [year, time] = yearTime.split(", ");
  return `${month}/${day}/${year} ${time}`;
};

const formatRangeValues = (label, min, max) => {
  if (min !== undefined && max !== undefined) {
    return `${label}: ${formatInstagramNumber(min)}-${formatInstagramNumber(max)}`;
  }
  if (min !== undefined) {
    return `${label}: ${formatInstagramNumber(min)}`;
  }
  if (max !== undefined) {
    return `${label}: ${formatInstagramNumber(max)}`;
  }
  return null;
};

const formatHashtagsOrMentions = (label, key, value) => {
  const separator = key === "hashtags" ? " " : " @";
  if (key === "hashtags" || key === "mentions") {
    return `${label}: ${key === "mentions" ? "@" : ""}${value.split(",").map((item) => (key === "hashtags" ? `#${item}` : item)).join(separator)}`;
  } else if (key === "creator_location") {
    return `${label}: ${value.split(",").map((code) => {
        const country = countriesList.find((country) => country.code === code.trim());
        return country ? country.name : code.trim(); // Return the country name or the code if not found
      }).join("|")}`;
  } else if (key === "video_language") {
    return `${label}: ${value.split(",").map((code) => {
        const language = videoLanguagesOptions.find((country) => country.code === code.trim());
        return language ? language.name : code.trim(); // Return the language name or the code if not found
      }).join("|")}`;
  }
  return `${label}: ${value.split(",").map((item) => item).join("|")}`;
};

const formatFilterValues = (filters, filteredParams) => {
  return filters
    .map(({ label, minKey, maxKey, value, numberValue }) => {
      const minValue = filteredParams[minKey];
      const maxValue = filteredParams[maxKey];

      switch (label) {
        case "Platform":
          if (value && filteredParams[value]) {
            // Use a regex to remove '%' characters from the platform value
            const platformValue = filteredParams[value].replace(/%/g, "");
            return `${label}: ${platformValue}`;
          }
          return null;

        case "Follower":
        case "Average likes":
        case "Average comments":
        case "Average views":
        case "Number of Content":
          return formatRangeValues(label, minValue, maxValue);

        default:
          if (value !== undefined && filteredParams[value] !== undefined) {
            const paramValue = filteredParams[value];
            return `${label}: ${paramValue === 1 ? "Yes" : paramValue === 0 ? "No" : paramValue}`;
          }

          if (numberValue !== undefined && filteredParams[numberValue] !== undefined) {
            return `${label}: > ${filteredParams[numberValue]} %`;
          }

          if (minKey === "hashtags" || minKey === "mentions" || minKey === "topics" || minKey === "tags" || minKey === "creator_location" || minKey === "video_category" || minKey === "video_language" || minKey === "duration") {
            if (filteredParams[minKey]) {
              return formatHashtagsOrMentions(label, minKey, filteredParams[minKey]);
            }
          } else if (minValue || maxValue) {
            return formatRangeValues(label, minValue, maxValue);
          }

          return null;
      }
    })
    .filter((value) => value !== null);
};

export const CreatorExportCsv = (data, name, selectedColumns, params) => {
  const filteredParams = Object.keys(params).reduce((acc, key) => {
    if (params[key] != null) {
      acc[key] = params[key];
    }
    return acc;
  }, {});

  if (data && data.length > 0) {
    const timeFilters = [formatDate(new Date())];

    const platformFilters = [{ label: "Platform", value: "platform" }];

    // Determine the label for follower count based on the platform
    const followerLabel = params.platform === "%instagram%" ? "Follower" : "Subscribers";

    const creatorFilters = [
      { label: followerLabel, minKey: "min_follower_count", maxKey: "max_follower_count" },
      { label: "Hashtag", minKey: "hashtags" },
      // { label: "Is Verified", value: "is_verified" },
      { label: "Number of Content", minKey: "min_no_of_content", maxKey: "max_no_of_content" },
      { label: "Most recent post", value: "recent_post" },
      { label: "Mentions", minKey: "mentions" },
      { label: "Bio keyword", value: "bio_keyword" },
      { label: "Channel Topics", minKey: "topics" },
      { label: "Tags", minKey: "tags" },
      { label: "Post keyword", value: "post_keyword" },
      { label: "Creator Location", minKey: "creator_location" },
      { label: "Video Category", minKey: "video_category" },
      { label: "Video Language", minKey: "video_language" },
      { label: "Video Duration", value: "duration" },
    ];

    const performanceFilters = [
      { label: "Average likes", minKey: "min_avg_likes", maxKey: "max_avg_likes" },
      { label: "Engagement rate", numberValue: "engagement_rate" },
      { label: "Average comments", minKey: "min_avg_comment", maxKey: "max_avg_comment" },
      { label: "Average views", minKey: "min_avg_views", maxKey: "max_avg_views" },
    ];

    const aiSearch = { label: "AI Search", value: "brand" };

    const exportTimeValues = timeFilters.join(", ");
    const platformFilterValues = formatFilterValues(platformFilters, filteredParams);
    const creatorFilterValues = formatFilterValues(creatorFilters, filteredParams);
    const performanceFilterValues = formatFilterValues(performanceFilters, filteredParams);

    const platformFilterString = platformFilterValues.join(", ");
    const creatorFilterString = creatorFilterValues.join(", ");
    const performanceFilterString = performanceFilterValues.join(", ");

    const exportTimeRow = ["Export Time:", exportTimeValues];
    const platformFilterRow = ["Platform Filter:", platformFilterString];
    const creatorFilterRow = ["Creator Filter:", creatorFilterString];
    const performanceFilterRow = ["Performance Filter:", performanceFilterString];
    const aiSearchRow = ["AI Search:", filteredParams[aiSearch.value] || ""];

    const filteredData = data.map((item) => {
      const selectedItem = {};

      selectedColumns.forEach((column) => {
        const value = item[column];

        if (value == null || value === -1) {
          // If the value is null or undefined, set it to "-"
          selectedItem[column] = "-";
        } else {
          switch (column) {
            case "avg_engagement":
              // Format avg_engagement as a percentage with 2 decimal places
              selectedItem[column] = `${value.toFixed(2)}%`;
              break;
            default:
              // For other fields, just assign the value directly
              selectedItem[column] = value;
          }
        }
      });

      return selectedItem;
    });

    const finalCsvDataList = () => {
      const csvData = Papa.unparse(filteredData);
      const csvRows = csvData.split("\n");
      csvRows.unshift("\n");
      csvRows.unshift(exportTimeRow.join(","));
      csvRows.unshift("");
      if (filteredParams[aiSearch.value]) {
        csvRows.unshift(aiSearchRow.join(","));
        csvRows.unshift("");
      }
      csvRows.unshift("");
      if (performanceFilterString) {
        csvRows.unshift(performanceFilterRow.join(","));
      }
      if (creatorFilterString) {
        csvRows.unshift(creatorFilterRow.join(","));
      }
      csvRows.unshift("");
      if (platformFilterString) {
        csvRows.unshift(platformFilterRow.join(","));
      }

      return csvRows.join("\n");
    };

    const finalCsvData = finalCsvDataList();

    const blob = new Blob([finalCsvData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute("download", `${name}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    toast.success("File exported successfully");
  } else {
    toast.error("No data available for export");
  }
};

export const ExportCsv = (data, name, selectedColumns, includeHeaders) => {
  if (data && data.length > 0) {
    const filteredData = data.map((item) => {
      const selectedItem = {};

      selectedColumns.forEach((column) => {
        const value = item[column];

        if (value == null || value === -1) {
          // If the value is null or undefined, set it to "-"
          selectedItem[column] = "-";
        } else {
          switch (column) {
            case "avg_engagement":
              // Format avg_engagement as a percentage with 2 decimal places
              selectedItem[column] = `${value.toFixed(2)}%`;
              break;
            default:
              // For other fields, just assign the value directly
              selectedItem[column] = value;
          }
        }
      });

      return selectedItem;
    });

    // Add the time filter
    const timeFilters = [formatDate(new Date())];
    const exportTimeRow = ["Export Time:", timeFilters.join(", ")];
    const favoriteListRow = ["Favorite List:", name];

    const csv = Papa.unparse(filteredData);
    const csvRows = csv.split("\n");
    csvRows.unshift("\n");

    if (includeHeaders === true) {
      csvRows.unshift(favoriteListRow);
    }

    // Add the export time row at the beginning
    csvRows.unshift(exportTimeRow.join(","));
    csvRows.unshift(""); // Add an empty row for separation

    const finalCsvData = csvRows.join("\n");

    const blob = new Blob([finalCsvData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute("download", `${name}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    toast.success("File exported successfully");
  } else {
    toast.error("No data available for export");
  }
};
