import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Paper } from '@mui/material';

// Helper function to get value by nested field
const getNestedValue = (obj, path) => {
    return path?.split('.').reduce((value, key) => value?.[key], obj);
};

// Helper function to format date
const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
    return new Date(dateString).toLocaleString(undefined, options);
};

const DataTable = ({ columns, data, rowsPerPageOptions = [5, 10, 15, 25] }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(15);

    // Handle pagination
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Paper>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>No</TableCell>
                            {columns?.map((column) => (
                                <TableCell key={column.field}>{column.headerName}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
            
                    <TableBody>
                        
                        {data
                            ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            ?.map((row, index) => (
                                <TableRow key={index}>
                                    
                                    <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                                    {columns?.map((column) => (
                                        <TableCell key={column.field}>
                                            {
                                                ['login_time', 'created_at', 'updated_at','last_login_time'].includes(column.field)
                                                    ? formatDate(getNestedValue(row, column.field))  // Format login_time, created_at, updated_at
                                                    : getNestedValue(row, column.field)
                                            }
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={data?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
};

export default DataTable;
