import { useState, useRef, useEffect } from "react";

const useDropdownOuter = (callback) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  const toggle = () => {
    setIsOpen((prev) => !prev);
    if (callback) {
      callback();
    }
  };

  const close = () => setIsOpen(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        close();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return { ref, isOpen, toggle, close };
};

export default useDropdownOuter;
