export const KEYS = {
  ADMIN_UAT: "admin-uat",
  UAT: "uat",
  EMAIL: "e-uat",
  FORGET: "forget",
  RESET: "reset",
}

const getStorage = () => {
  const setItem = (key, value) => {
    localStorage.setItem(key, value);
  };

  const getItem = (key) => {
    const value = localStorage.getItem(key);
    return value;
  };

  const removeItem = (key) => {
    localStorage.removeItem(key);
  };

  const clearItems = () => {
    localStorage.clear();
  };

  return { setItem, getItem, removeItem, clearItems };
};

export default getStorage;
