import { TextField } from "@mui/material";
import React, { Fragment } from "react";
import { Col, Row } from "reactstrap";

import { Btn, Image } from "../../../AbstractElement";
import { dynamicImage } from "../../../Helper";
import getStorage, { KEYS } from "../../../Helper/getStorage";
// import { useAuthContext } from "../../../Context/Auth/AuthContext";

export const ProfileContainer = () => {
  const { getItem } = getStorage();
  const { user } = JSON.parse(getItem(KEYS.UAT)) || {};
  // const { handleInstagramLogin } = useAuthContext();

  return (
    <Fragment>
      <div className="profile-page">
        {/* <Breadcrumb>
          <BreadcrumbItem>
            <a href="#">
              <Image src={dynamicImage("svg/home-2.svg")} /> Home
            </a>
          </BreadcrumbItem>
          <BreadcrumbItem active>My Profile</BreadcrumbItem>
        </Breadcrumb> */}

        <div className="profile-box ">
          <div className="profile-wrap">
            <Image src={dynamicImage("user/2.jpg")} />
            <div className="profile-detail">
              <h5>{user?.name}</h5>
              <h6 className="text-capitalize">{user?.account_type}</h6>
            </div>
          </div>
          <div className="edit-btn">
            <Btn className="btn-pill-light">
              <Image src={dynamicImage("svg/edit.svg")} /> Edit
            </Btn>
          </div>
        </div>

        <div className="about-wrap width-space">
          <h4 className="inner-title">About Me</h4>
          <p>Emily Brown is an adventurous travel influencer known for her breathtaking photography and captivating travel stories. With a passion for exploring new destinations and experiencing diverse cultures, Emily has built a loyal following who eagerly anticipate her next adventure.</p>
        </div> 

        <div className="general-details-wrap width-space">
          <h5 className="inner-title">General Details</h5>
          <div className="input-wrap"> 
            <Row>
              <Col lg={6}>
                <div className="form-warp pb-4">
                  <TextField fullWidth label="Name" disabled defaultValue={user?.name} id="fullWidth" />
                </div>
              </Col>
              <Col lg={6}>
                <div className="form-warp pb-4">
                  <TextField fullWidth label="Email Address" disabled type="email" defaultValue={user?.email} id="fullWidth" />
                </div>
              </Col>
              <Col lg={6}>
                <div className="form-warp pb-4">
                  <TextField fullWidth label="Gender" disabled type="email" defaul  tValue="Female" id="fullWidth" />
                </div>
              </Col>
              <Col lg={6}>
                <div className="form-warp pb-4">
                  <TextField fullWidth label="Country" disabled defaultValue="India" id="fullWidth" />
                </div>
              </Col>
              <Col lg={6}>
                <div className="form-warp">
                  <TextField fullWidth label="State" disabled defaultValue="Gujarat" id="fullWidth" />
                </div>
              </Col>
              <Col lg={6}>
                <div className="form-warp">
                  <TextField fullWidth label="PIN/Postal Code" disabled defaultValue="390056" id="fullWidth" />
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <div className="account-wrap width-space">
          <h5 className="inner-title">Link Account</h5>
          <ul className="account-list">
            <li className="account-link">
              <Btn className="btn-outline">
                <Image src={dynamicImage("svg/youtube.svg")} /> Youtube
              </Btn>
            </li>
            <li className="account-link">
              {/* <Btn className="btn-outline" onClick={handleInstagramLogin}> */}
              <Btn className="btn-outline">
                <Image src={dynamicImage("svg/insta.svg")} /> Instagram
              </Btn>
            </li>
          </ul>
        </div>

        <div className="interest-wrap width-space">
          <h5 className="inner-title">Intrest Area</h5>
          <ul className="teg-list">
            <li className="teg-link">Fashion</li>
            <li className="teg-link">Lifestyle</li>
            <li className="teg-link">Luxury</li>
          </ul>
        </div>

        <section className="lb-space"></section>
      </div>
    </Fragment>
  );
};
