import React, { useState } from "react";
import { dynamicImage, dynamicSvg } from "../../../Helper";
import { Image } from "../../../AbstractElement";
import { Layout } from "./Common/Logout";


const Header = () => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleClick = () => {
    document.querySelector(".page-header")?.classList.toggle("close_icon");
    document.querySelector(".sidebar-wrapper")?.classList.toggle("close_icon");
    setIsOpen(!isOpen);
  };

  return (
     <div className="page-header">
        <div className="header-wrapper row m-0">
          <div className="header-logo-wrapper col-auto p-0">
            <div className="logo-wrapper">
              <a href="index.html">
                <Image className="logo" src={dynamicImage('logo.svg')} />
              </a>
            </div>
            <div className="toggle-sidebar" onClick={toggleClick}>
              <svg className="sidebar-toggle">
                <use
                  href={`${dynamicSvg('admin-sprite.svg')}#fill-animation`}
                ></use>
              </svg>
            </div>
          </div>
          <div className="nav-right col-xxl-7 col-xl-6 col-auto box-col-6 pull-right right-header p-0 ms-auto">
            <ul className="nav-menus">
              <li className="serchinput">
                <div className="serchbox">
                  <svg>
                    <use
                      href={`${dynamicSvg('admin-sprite.svg')}#fill-search`}
                    ></use>
                  </svg>
                </div>
              </li>
              <Layout />
            </ul>
          </div>
          <script className="result-template" type="text/x-handlebars-template">
            <div className="ProfileCard u-cf">
              <div className="ProfileCard-avatar">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="feather feather-airplay m-0"
                >
                  <path d="M5 17H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1"></path>
                  <polygon points="12 15 17 21 7 21 12 15"></polygon>
                </svg>
              </div>
              <div className="ProfileCard-details">
                <div className="ProfileCard-realName">name</div>
              </div>
            </div>
          </script>
          <script className="empty-template" type="text/x-handlebars-template">
            <div className="EmptyMessage">
              Your search turned up 0 results. This most likely means the
              backend is down, yikes!
            </div>
          </script>
        </div>
      </div>
  );
};

export default Header;
