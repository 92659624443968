import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";

import { API } from "../../../Api/ApiList";
import { ROUTES } from "../../../Routes/Routes";
import ResetPasswordContainer from "../../../Component/Auth/ResetPassword";

const ResetPassword = () => {
  const [enterAuth, setEnterAuth] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const pathToken = location.pathname.split("/")[2];

  useEffect(() => {
    if (pathToken) {
      const updatePassHandler = async () => {
        try {
          const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/${API.auth.verifyEmailApi}/` + pathToken);
          if (response.status === 201 || response.status === 200) {
            toast.success(response.data.message);
            setEnterAuth(true);
          }
        } catch (error) {
          setEnterAuth(false);
          navigate(ROUTES.Auth.Login)
          toast.error("Page Not Found !!!");
        }
      };
      updatePassHandler();
    }
  }, [navigate, pathToken]);

  return <Fragment>{enterAuth && <ResetPasswordContainer pathToken={pathToken} />}</Fragment>;
};

export default ResetPassword;
