import React from "react";
import { Container } from "reactstrap";
import { Link } from "react-router-dom";

import { dynamicImage } from "../../../Helper";
import { Image } from "../../../AbstractElement";
import { ROUTES } from "../../../Routes/Routes";
import { ArrowRightAlt } from "@mui/icons-material";
import { useAuthContext } from "../../../Context/Auth/AuthContext";

const BannerSection = () => {
  const { isAuthenticated } = useAuthContext();

  return (
    <>
    <section className="home-section st-space">
      <Container>
        <div className="home-wrapper">
          <Image className="bg-1 img-fluid" src={dynamicImage("bg1.png")} />
          <Image className="bg-2 img-fluid" src={dynamicImage("bg2.png")} />
          {/* <ReviewBox /> */}
          <h1>Discover the right Influencers </h1>
          <h1>
             for<span> Impactful </span> collabs
          </h1>
          <p className="pt-4 sb-space">We're in Beta - Please share your feedback & help us perfect the platform</p>
          <div className="home-btns">
            <Link className="btn btn-theme" to={isAuthenticated ? ROUTES.Pages.Creator : ROUTES.Auth.Login}>
              Try Now
              {/* <ArrowRightAlt fontSize="large" /> */}
            </Link>
          </div>
        </div>
      </Container>
    </section>
   
    </>
  );
};

export default BannerSection;
