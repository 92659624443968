import React from "react";
import { Add } from "@mui/icons-material";
import { Button, Chip, TextField } from "@mui/material";

import { useAutoFocus } from "../../Hooks/useAutoFocus";

const InputMultipleAddCommon = ({ title, inputValue, setInputValue, items = [], setItems, multiple = true }) => {
  const inputRef = useAutoFocus(title);

  const addItem = (item) => {
    // Add appropriate prefix based on the title
    if (title === "Hashtags") {
      item = `#${item}`;
    } else if (title === "Mentions") {
      item = `@${item}`;
    }

    // For multiple items, check for duplicates and add to the array
    if (multiple) {
      if (typeof item === "string" && item.trim() !== "" && !items.includes(item.trim())) {
        setItems((prevItems) => [...prevItems, item.trim()]); // Append new item without replacing the entire array
      }
    } else {
      // Single item, just replace the state
      if (typeof item === "string" && item.trim() !== "") {
        setItems(item.trim());
      }
    }
  };

  const handleAddItem = () => {
    if (inputValue.trim() !== "") {
      // Split the input by spaces and add each part as an individual item
      const words = inputValue.split(/\s+/); // Split by space (one or more spaces)
      words.forEach((word) => {
        if (word.trim() !== "") {
          addItem(word);
        }
      });
      setInputValue(""); // Clear the input field after adding the items
    }
  };

  const handleDeleteItem = (item) => {
    if (multiple) {
      const newItems = items.filter((i) => i !== item);
      setItems(newItems); // Update state only
    } else {
      setItems([]); // Clear items for single selection
    }
  };

  return (
    <div className="text-input-wrap">
      <TextField inputRef={inputRef} id={`${title.toLowerCase()}-list`} placeholder={title} className="text-input" value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
      <Button onClick={handleAddItem}>
        <Add fontSize="small" />
      </Button>
      <div className="chip-wrapper">{multiple ? Array.isArray(items) && items.map((item, index) => <Chip key={index} label={item} onDelete={() => handleDeleteItem(item)} />) : items && items.length > 0 && <Chip key="single" label={items} onDelete={() => handleDeleteItem(items)} />}</div>
    </div>
  );
};

export default InputMultipleAddCommon;
