import { createContext, useCallback, useContext, useState } from "react";

// Define the initial state for the context
const initialState = {
  searchQuery: "",
  creatorProfileData: [],
  isSubmitted: false,
};

export const CreatorProfileContext = createContext();

export const CreatorProfileContextProvider = ({ children }) => {
  const [creatorProfileState, setCreatorProfileState] = useState(initialState);

  // Update the state object
  const updateState = useCallback((newState) => {
    setCreatorProfileState((prevState) => ({ ...prevState, ...newState }));
  }, []);

  // Handlers to update specific parts of the state
  const onChangeSearchQuery = (value) => {
    updateState({
      searchQuery: value,
      isSubmitted: value.trim() !== "" ? creatorProfileState.isSubmitted : false,
    });
  };

  const clearSearch = () => {
    updateState({
      searchQuery: "",
      isSubmitted: false,
    });
  };

  return <CreatorProfileContext.Provider value={{ creatorProfileState, updateState, onChangeSearchQuery, clearSearch }}>{children}</CreatorProfileContext.Provider>;
};

export const useCreatorProfileContext = () => {
  return useContext(CreatorProfileContext);
};
