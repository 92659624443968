import React from "react";
import { FormGroup, Modal, ModalBody, ModalHeader } from "reactstrap";

import { dynamicImage } from "../../../Helper";
import { Btn, Image } from "../../../AbstractElement";
import { useCreatorContext } from "../../../Context/Creator/CreatorContext";

export const ContactOptionModal = () => {
  const { contactOptionModal, toggleContactOptionModal } = useCreatorContext();

  return (
    <Modal centered className="contact-addlist" isOpen={contactOptionModal} toggle={toggleContactOptionModal}>
      <ModalHeader toggle={toggleContactOptionModal} className="border-0"></ModalHeader>
      <ModalBody>
        <h5 className="modal-title">Which contact options should creators have?</h5>
        <FormGroup className="position-relative mt-lg-4 mt-3">
          <Image src={dynamicImage("svg/search-dark.svg")} className="form-icon" />
          <input className="form-control" placeholder="search your list hear..." type="search" />
        </FormGroup>

        <ul className="contact-option-wrap scroll-bar">
          <li className="contact-box">
            <input type="checkbox" className="check-box" id="any" />
            <label htmlFor="any" className="note-label">
              Any contact option <p>Get creators with at least 1 available contact option</p>
            </label>
          </li>
          <li className="contact-box active">
            <input type="checkbox" className="check-box" id="mail" />
            <label htmlFor="mail">
              <span>
                <Image src={dynamicImage("svg/mail.svg")} />
              </span>
              Email
            </label>
          </li>
          <li className="contact-box">
            <input type="checkbox" className="check-box" id="phone" />
            <label htmlFor="phone">
              <span>
                <Image src={dynamicImage("svg/call.svg")} />
              </span>
              Phone
            </label>
          </li>
          <li className="contact-box">
            <input type="checkbox" className="check-box" id="Instagram" />
            <label htmlFor="Instagram">
              <span>
                <Image src={dynamicImage("svg/insta.svg")} />
              </span>
              Instagram
            </label>
          </li>
          <li className="contact-box">
            <input type="checkbox" className="check-box" id="YouTube" />
            <label htmlFor="YouTube">
              <span>
                <Image src={dynamicImage("svg/youtube.svg")} />
              </span>
              YouTube
            </label>
          </li>
          <li className="contact-box">
            <input type="checkbox" className="check-box" id="TikTok" />
            <label htmlFor="TikTok">
              <span>
                <Image src={dynamicImage("svg/tik-tok.svg")} />
              </span>
              TikTok
            </label>
          </li>
          <li className="contact-box">
            <input type="checkbox" className="check-box" id="viber" />
            <label htmlFor="viber">
              <span>
                <Image src={dynamicImage("svg/viber.svg")} />
              </span>
              viber
            </label>
          </li>
          <li className="contact-box">
            <input type="checkbox" className="check-box" id="vk" />
            <label htmlFor="vk">
              <span>
                <Image src={dynamicImage("svg/vk.svg")} />
              </span>
              VK
            </label>
          </li>
          <li className="contact-box">
            <input type="checkbox" className="check-box" id="weibo" />
            <label htmlFor="weibo">
              <span>
                <Image src={dynamicImage("svg/weibo.svg")} />
              </span>
              weibo
            </label>
          </li>
          <li className="contact-box">
            <input type="checkbox" className="check-box" id="whatsapp" />
            <label htmlFor="whatsapp">
              <span>
                <Image src={dynamicImage("svg/whatsapp.svg")} />
              </span>
              whatsapp
            </label>
          </li>
          <li className="contact-box">
            <input type="checkbox" className="check-box" id="Telegram" />
            <label htmlFor="Telegram">
              <span>
                <Image src={dynamicImage("svg/Telegram.svg")} />
              </span>
              Telegram
            </label>
          </li>
          <li className="contact-box">
            <input type="checkbox" className="check-box" id="skype" />
            <label htmlFor="skype">
              <span>
                <Image src={dynamicImage("svg/skype.svg")} />
              </span>
              skype
            </label>
          </li>
          <li className="contact-box">
            <input type="checkbox" className="check-box" id="snapchat" />
            <label htmlFor="snapchat">
              <span>
                <Image src={dynamicImage("svg/snapchat.svg")} />
              </span>
              snapchat
            </label>
          </li>
          <li className="contact-box">
            <input type="checkbox" className="check-box" id="pinterest" />
            <label htmlFor="pinterest">
              <span>
                <Image src={dynamicImage("svg/pinterest.svg")} />
              </span>
              pinterest
            </label>
          </li>
          <li className="contact-box">
            <input type="checkbox" className="check-box" id="linktree" />
            <label htmlFor="linktree">
              <span>
                <Image src={dynamicImage("svg/linktree.svg")} />
              </span>
              linktree
            </label>
          </li>
          <li className="contact-box">
            <input type="checkbox" className="check-box" id="Line" />
            <label htmlFor="Line">
              <span>
                <Image src={dynamicImage("svg/Line.svg")} />
              </span>
              Line
            </label>
          </li>
          <li className="contact-box">
            <input type="checkbox" className="check-box" id="kakao" />
            <label htmlFor="kakao">
              <span>
                <Image src={dynamicImage("svg/kakao.svg")} />
              </span>
              kakao
            </label>
          </li>
          <li className="contact-box">
            <input type="checkbox" className="check-box" id="facebook" />
            <label htmlFor="facebook">
              <Image src={dynamicImage("svg/facebook-icon.svg")} /> facebook
            </label>
          </li>
          <li className="contact-box">
            <input type="checkbox" className="check-box" id="bbm" />
            <label htmlFor="bbm">
              <Image src={dynamicImage("svg/bbm.svg")} /> BBM
            </label>
          </li>
          <li className="contact-box">
            <input type="checkbox" className="check-box" id="apple-music" />
            <label htmlFor="apple-music">
              <Image src={dynamicImage("svg/apple-music.svg")} /> apple-music
            </label>
          </li>
          <li className="contact-box">
            <input type="checkbox" className="check-box" id="tumblr" />
            <label htmlFor="tumblr">
              <Image src={dynamicImage("svg/tumblr.svg")} /> tumblr
            </label>
          </li>
          <li className="contact-box">
            <input type="checkbox" className="check-box" id="twitch" />
            <label htmlFor="twitch">
              <Image src={dynamicImage("svg/twitch.svg")} /> twitch
            </label>
          </li>
          <li className="contact-box">
            <input type="checkbox" className="check-box" id="twitter" />
            <label htmlFor="twitter">
              <Image src={dynamicImage("svg/twitter.svg")} /> twitter
            </label>
          </li>
          <li className="contact-box">
            <input type="checkbox" className="check-box" id="kik" />
            <label htmlFor="kik">
              <Image src={dynamicImage("svg/kik.svg")} /> kik
            </label>
          </li>
          <li className="contact-box">
            <input type="checkbox" className="check-box" id="wechat" />
            <label htmlFor="wechat">
              <Image src={dynamicImage("svg/wechat.svg")} /> wechat
            </label>
          </li>
        </ul>
        <Btn block className="btn-pill-light mt-4">
          Add
        </Btn>
      </ModalBody>
    </Modal>
  );
};
