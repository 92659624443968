import React from "react";
import { WishlistContainer } from "../../../Component/User/Wishlist";
import { dividerClasses } from "@mui/material";
import { Col, Row } from "reactstrap";
import { Btn, Image } from "../../../AbstractElement";
import { dynamicImage, dynamicSvg } from "../../../Helper";

export const UserProfile = () => {
  return <div>
    <div className="user-profile">
      <Row className="gy-4">
        <Col lg={12}>
          <div className="user-img">
            <div className="profile-img">
              <Image className="img-fluid" src={dynamicImage("user/3.jpg")} />
            </div>
            <div className="user-details">
              <div className="w-100"> 
                <h4>Zenith Global <Image src={dynamicImage("svg/verify.svg")} />
                </h4>
                <div className="custom-badge-url mb-3">
                  <h6 className="mb-0"><Image src={dynamicImage("svg/insta.svg")} /> @xxxdeerread 
                  </h6>
                </div> 
                <p>Entertainment</p> 
              </div>
              <div className="d-flex align-items-center flex-column justify-content-between"> 
                <div className="edit-icon">
                  <Image src={dynamicImage("svg/edit-1.svg")} />
                  <p>Edit</p>
                </div>
                <div className="custom-analytics-selected">
                      <label htmlFor="dateRangeSelect">Last updated</label>
                      <select class="form-control" id="dateRangeSelect">
                        <option>7 days</option>
                        <option selected>28 days</option>
                        <option>90 days</option>
                        <option >365 days</option>
                        <option>Lifetime</option>
                        <option>2024</option>
                        <option>2023</option>
                        <option>November</option>
                        <option>October</option>
                        <option>September</option>
                        <option>Custom</option>
                      </select>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col lg={12}>
          <div className="row">
            <div className="col-xl-8 ">
              <div className="custom-media-title mb-3"> 
                <h4>Performance</h4>
              </div>
              <div className="custom-media-card">
                <div className="row">
                  <div className="col-xl-6 mb-3"> 
                    <div className="card media-card">
                      <ul className="media-card-data" >
                          <li className="share-button">
                            <a href="#"> 
                              Followers
                            </a> 
                            <span>3.6M</span>
                          </li> 
                          <li className="share-button">
                            <a href="#"> 
                            Following
                            </a> 
                            <span>3K</span> 
                          </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-xl-6 mb-3"> 
                    <div className="card media-card">
                      <ul className="media-card-data" >
                          <li className="share-button">
                            <a href="#"> 
                            Content
                            </a> 
                            <span>3.8M</span>
                          </li> 
                          <li className="share-button">
                            <a href="#"> 
                            Watch Time
                            </a> 
                            <span>3K</span> 
                          </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-xl-6"> 
                    <div className="card media-card">
                      <ul className="media-card-data" >
                          <li className="share-button">
                            <a href="#"> 
                            Engagement Rate
                            </a> 
                            <span>3.3M</span> 
                          </li> 
                          <li className="share-button">
                            <a href="#"> 
                            Views
                            </a> 
                            <span>3K</span> 
                          </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-xl-6"> 
                    <div className="card media-card">
                      <ul className="media-card-data" >
                          <li className="share-button">
                            <a href="#"> 
                            Likes
                            </a> 
                            <span>3.6M</span>
                          </li> 
                          <li className="share-button">
                            <a href="#"> 
                            Comments
                            </a> 
                            <span>3K</span> 
                          </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 mb-3"> 
              <div className="custom-media-title mb-3"> 
                <h4>Overview</h4>
              </div>
              <ul className="card custom-media-card">
                <li className="custom-subtitle pt-0">
                  <h5 className="mb-2">About Me</h5>
                  <p>Emily Brown is an adventurous travel influencer known for her breathtaking photography and captivating travel stories.</p>
                </li>
                <li className="custom-details"> 
                    <h5>Gender:</h5>
                    <span>Female</span> 
                </li>
                <li className="custom-details"> 
                    <h5>Location:</h5>
                    <span>New York, USA</span>
                </li>
                <li className="custom-details">
                    <h5 >Account Type:</h5>
                    <span>Business</span> 
                </li>
                {/* <div className="user-location">     
                  <p>Male</p> 
                  <svg className="gender-icon"> 
                    <use href={`${dynamicSvg('admin-sprite.svg')}#stroke-gender`}></use>
                  </svg>
                  <Image className="img-fluid" src={dynamicImage("svg/")} alt="location" />
                </div>  */}
                {/* <div className="custom-engagement">
                  <p>Mumbai, India</p> 
                  <Image className="img-fluid" src={dynamicImage("svg/location.svg")} alt="location" />
                </div>  */}
                {/* <div className="custom-engagement">
                  <p>Creator</p> 
                  <svg className="gender-icon" >
                    <use href={`${dynamicSvg('admin-sprite.svg')}#stroke-ui-kits`}></use>
                  </svg>
                </div> */}
              </ul>
            </div>
            
          </div>
          <div className="user-box mt-4">
            <div className="custom-media-title"> 
              <h4 className="mb-2">Recent post</h4>
            </div>
            <Row>
              <Col lg={3}>
                <div className="top-post">
                    <div className="d-flex">
                        <Image className="img-fluid" src={dynamicImage("user/3.jpg")} />
                        <p>Zenith Global
                          <span>latest updated</span>
                        </p>
                    </div>
                    <div className="post-img">
                      <Image className="img-fluid" src={dynamicImage("product/product-1.jpg")} />
                    </div>
                </div>
              </Col>
              <Col lg={3}>
                <div className="top-post">
                    <div className="d-flex">
                        <Image className="img-fluid" src={dynamicImage("user/3.jpg")} />
                        <p>Zenith Global
                          <span>latest updated</span>
                        </p>
                    </div>
                    <div className="post-img">
                      <Image className="img-fluid" src={dynamicImage("product/product-1.jpg")} />
                    </div>
                </div>
              </Col>
              <Col lg={3}>
                <div className="top-post">
                    <div className="d-flex">
                        <Image className="img-fluid" src={dynamicImage("user/3.jpg")} />
                        <p>Zenith Global
                          <span>latest updated</span>
                        </p>
                    </div>
                    <div className="post-img">
                      <Image className="img-fluid" src={dynamicImage("product/product-1.jpg")} />
                    </div>
                </div>
              </Col>
              <Col lg={3}>
                <div className="top-post">
                    <div className="d-flex">
                        <Image className="img-fluid" src={dynamicImage("user/3.jpg")} />
                        <p>Zenith Global
                          <span>latest updated</span>
                        </p>
                    </div>
                    <div className="post-img">
                      <Image className="img-fluid" src={dynamicImage("product/product-1.jpg")} />
                    </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col lg={12}>
          <div className="custom-media-title"> 
            <h4>Followers Like and Trends</h4>
          </div>
          <div class="mt-4">
            <div class="row">
              <div class="col-md-3 mb-4 ">
                <div class="card shadow-sm custom-trend-card card ">
                  <div className="card-header">
                    <h5 class="card-title">Followers Trend</h5>
                  </div>
                  <div class="card-body"> 
                  </div>
                </div> 
              </div>
              <div class="col-md-3 mb-4 ">
                <div class="card shadow-sm custom-trend-card card ">
                  <div className="card-header">
                    <h5 class="card-title">Link and Trend</h5>
                  </div>
                  <div class="card-body">  

                  </div>
                </div>
              </div>
              <div class="col-md-3 mb-4 ">
                <div class="card shadow-sm custom-trend-card card ">
                  <div className="card-header">
                    <h5 class="card-title">Top hashtags</h5>
                  </div>
                  <div class="card-body">
                    <div className="col-12 mb-4">
                        <div className="custom-badge-tags">
                          <span>#Travel</span>
                          <span>#Foodie</span>
                          <span>#Lifestyle</span>
                          <span>#Influencer</span>
                          <span>#Beauty</span>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3 mb-4 ">
                <div class="card shadow-sm custom-trend-card card ">
                  <div className="card-header">
                    <h5 class="card-title">Top Tagged</h5>
                  </div>
                  <div class="card-body">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col lg={12}>
          <div class="mt-4">
            <div class="row">
              <div class="col-md-3 mb-4 ">
                <div class="card shadow-sm custom-trend-card card ">
                  <div className="card-header">
                    <h5 class="card-title">Followers Types</h5>
                  </div>
                  <div class="card-body"> 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>



        
        {/* <Col lg={12}>
          <ul class="user-tab">
            <li className="active"> Performance</li>
            <li> Comment analysis
              <span className="badge">Ai powered</span>
            </li>
            <li>Growth & interests </li>
            <li>Audience </li>
          </ul>
        </Col>
        <Col lg={12}>
          <ul>
            <li className="performance-tab active">
                <h4>Performance</h4>
                <ul>
                  <li>
                    <div className="d-flex">
                      <Image src={dynamicImage("svg/user.svg")} />
                      <h3>20.28M</h3>
                    </div>
                    <p>Followers</p>
                    <span className="badge">81% Real</span>
                  </li>
                  <li>
                    <div className="d-flex">
                      <h3>160.78%</h3>
                    </div>
                    <p>Engagement rate</p>
                    <span className="badge">159.7% higher than median</span>
                  </li>
                  <li>
                    <div className="d-flex">
                      <h3>-</h3>
                    </div>
                    <div className="d-flex">
                      <div>
                        <p>Sponsored posts</p>
                        <p>performance</p>
                      </div>
                      <Image src={dynamicImage("svg/clock.svg")} />
                    </div>
                  </li>
                </ul>
            </li>
          </ul>
        </Col> */}
      </Row>
    </div>
  </div>;
};
