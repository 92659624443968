import React, { Fragment } from "react";
import { Navigate, Outlet } from "react-router-dom";

import { ROUTES } from "./Routes";
import { useAuthContext } from "../Context/Auth/AuthContext";
import { useAdminAuthContext } from "../Context/Auth/AdminAuthContext";

export const PrivateRoute = () => {
  const { isAuthenticated } = useAuthContext();

  return isAuthenticated ? (
    <Outlet />
  ) : (
    <Fragment>
      <Navigate to={ROUTES.Home} />
    </Fragment>
  );
};

export const AdminPrivateRoute = () => {
  const { isAdminAuthenticated } = useAdminAuthContext();

  return isAdminAuthenticated ? (
    <Outlet />
  ) : (
    <Fragment>
      <Navigate to={ROUTES.Admin.AdminLogin} />
    </Fragment>
  );
};