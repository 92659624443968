import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { Col, Form, FormGroup, Row } from "reactstrap";

import { dynamicImage } from "../../../Helper";
import PassStrength from "../Common/PassStrength";
import { Btn, Image } from "../../../AbstractElement";
import { useAuthContext } from "../../../Context/Auth/AuthContext";
import useApiState from "../../../Hooks/useApiState";

const ResetPasswordContainer = ({ pathToken }) => {
  const [showPass, setShowPass] = useState(false);
  const toggleShowPass = () => setShowPass(!showPass);
  const navigate = useNavigate();
  const { getPasswordStrength, ResetPassSchema, handleResetPassword } = useAuthContext();
  const [passwordStrength, setPasswordStrength] = useState("");
  const { loading, setLoading } = useApiState()

  const {register,handleSubmit,formState: { errors },} = useForm({ resolver: yupResolver(ResetPassSchema) });
  const handlePasswordChange = (event) => {
    const password = event.target.value;
    setPasswordStrength(getPasswordStrength(password));
  };

  const onSubmit = (data) => handleResetPassword({ pathToken, data, navigate, setLoading });

  return (
    <section className="verification-section p-0">
      <Row className="m-0 h-100">
        <Col xxl="6" lg="7" md="8" xs="12" className="mx-auto p-0">
          <div className="verification-form">
            <div>
              <Image className="verify-gif" src={dynamicImage("gif/reset_pass.gif")} />
              <div className="title">
                <h3>Reset Password</h3>
                <p>Enter a new password below to regain access to your account.</p>
              </div>
              <Form onSubmit={handleSubmit(onSubmit)} noValidate>
                <FormGroup className="position-relative">
                  <Image src={dynamicImage("svg/lock.svg")} className="form-icon" />
                  <input {...register("password")} onChange={handlePasswordChange} className="form-control" placeholder="Password" type={showPass ? "text" : "password"} />
                  <div className="password-wrap" onClick={toggleShowPass}>
                    <Image src={dynamicImage(`svg/${showPass ? "eye" : "eye-slash"}.svg`)} />
                  </div>
                  {errors.password && (
                    <span className="required-note">
                      <small>{errors.password.message}</small>
                    </span>
                  )}
                </FormGroup>
                {passwordStrength && <PassStrength passwordStrength={passwordStrength} />}

                <FormGroup className="position-relative">
                  <Image src={dynamicImage("svg/lock.svg")} className="form-icon" />
                  <input {...register("confirmPassword")} className="form-control" placeholder="Confirm Password" type={showPass ? "text" : "password"} />
                  <div className="password-wrap" onClick={toggleShowPass}>
                    <Image src={dynamicImage(`svg/${showPass ? "eye" : "eye-slash"}.svg`)} />
                  </div>
                  {errors.confirmPassword && (
                    <span className="required-note">
                      <small>{errors.confirmPassword.message}</small>
                    </span>
                  )}
                </FormGroup>
                <div className="action-btn mt-5">
                  <Btn block type="submit" className="btn-theme" disabled={loading} loading={loading}>
                    Confirm Password
                  </Btn>
                </div>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default ResetPasswordContainer;
