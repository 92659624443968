import React  from 'react';

// Higher-Order Component for Accordion
const AccordionContent  = ({ title, children }) => {
    return (
        <div>
            <h5>{title}</h5>
            <p>{children}</p>
        </div>
    );
};


export default AccordionContent ;
