import React from "react";

import { dynamicImage } from "../../Helper";
import { Image } from "../../AbstractElement";
import useDropdownOuter from "../../Hooks/useDropdownOuter";

const CommonSelect = ({ options, selectedOptions, setSelectedOptions, text, multipleSelect, outer, noCheck }) => {
  const { ref, isOpen, toggle } = useDropdownOuter();

  const handleSelect = (option) => {
    setSelectedOptions((prevSelectedOptions) => {
      if (multipleSelect) {
        if (prevSelectedOptions.includes(option)) {
          return prevSelectedOptions.filter((selectedOption) => selectedOption !== option);
        } else {
          return [...prevSelectedOptions, option];
        }
      } else {
        return prevSelectedOptions.includes(option) ? [] : [option];
      }
    });
  };

  const handleInputChange = (e, option) => {
    e.stopPropagation();
    handleSelect(option);
  };

  return (
    <div ref={ref} className={`select-form ${isOpen ? "open" : ""}`}>
      <div className={outer ? "form-select" : "inner-select"} onClick={toggle}>
        {selectedOptions?.length > 0 ? <span className="selected text-capitalize">{multipleSelect ? selectedOptions.join(", ") : selectedOptions[0]}</span> : <span>{text}</span>}
        <Image src={dynamicImage("svg/arrow-down.svg")} />
      </div>
      {isOpen && (
        <ul className="form-option inner-form scroll-bar">
          {options?.map((option, index) => (
            <li key={index} onClick={() => handleSelect(option)}>
              <div className="inner-list">
                {!noCheck && <input type="checkbox" className="check-box" id={`check-${option}`} checked={selectedOptions?.includes(option)} onChange={(e) => handleInputChange(e, option)} />}
                <label htmlFor={`check-${option}`}>{option}</label>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CommonSelect;
