import React, { useState } from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";

import { dynamicImage } from "../../../Helper";
import { Image } from "../../../AbstractElement";
import { useCreatorContext } from "../../../Context/Creator/CreatorContext";

const SortList = ({handleSortList}) => {
  const { platFormSelectedItem } = useCreatorContext()
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const [selectedItem, setSelectedItem] = useState("Sort by");
  const handleSelect = (value) => setSelectedItem(value);
  const followerHead = platFormSelectedItem?.title === "instagram";

  const handleSort = (param, sort, title)=>{
    handleSortList(param, sort)
    handleSelect(title)
  }

  return (
    <div className="short-bay-box">
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle caret className="shortby-btn">
          <Image src={dynamicImage("svg/setting.svg")} alt="Settings Icon" /> {selectedItem}
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={() => handleSort("avg_engagement","asc","Sort by Low to High Engagement rate")}>Sort by Low to High Engagement rate</DropdownItem>
          <DropdownItem onClick={() => handleSort("avg_engagement","desc","Sort by High to Low Engagement rate")}>Sort by High to Low Engagement rate</DropdownItem>
          <DropdownItem onClick={() => handleSort("follower_count", "asc", "Sort by Low to High Follower")}>Sort by Low to High {followerHead ? "Followers" : "Subscribers"}</DropdownItem>
          <DropdownItem onClick={() => handleSort("follower_count", "desc", "Sort by High to Low Followers")}>Sort by High to Low {followerHead ? "Followers" : "Subscribers"}</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default SortList;
