import React from "react";
import { Image } from "../../../AbstractElement";
import { dynamicImage } from "../../../Helper";

export const NoDataFound = ({colSpan}) => {
  return (
    <tr className="table-no-data p-5">
      <td colSpan={colSpan} className="text-center no-found">
        <Image src={dynamicImage("svg/no-data.svg")} />
        <p>
          <strong>No data found</strong>
        </p>
      </td>
    </tr>
  );
};
