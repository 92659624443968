import React from "react";
import { FilterList } from "@mui/icons-material";

import { useCreatorContext } from "../../../../Context/Creator/CreatorContext";

export const ContactAndMoreFilter = () => {
  const { toggleFilterCanvas } = useCreatorContext();

  return (
    <div className="filter-option-box">
      {/* <div className="btn filter-btn" onClick={toggleContactOptionModal} id="Tooltip-contact">
        <Image src={dynamicImage("svg/contact-option.svg")} alt="Settings Icon" />
        <Tooltips target="Tooltip-contact" text="Contact option" />
      </div> */}
      <div className="btn filter-btn" onClick={toggleFilterCanvas} id="Tooltip-moreFilter">
        <FilterList fontSize="medium" />
        More filters
      </div>
    </div>
  );
};
