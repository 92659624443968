import React, { useState } from "react";
import { Col, Form, FormGroup, Row } from "reactstrap";

import { Btn } from "../../../AbstractElement";
import CommonSelect from "../../Common/CommonSelect";
import { categoryOptions, genderOptions } from "../../../Data/FindCreatorData";

const SummarizeDetailsContainer = () => {
  const [genderSelected, setGenderSelected] = useState([]);
  const [categorySelected, setCategorySelected] = useState([]);
  return (
    <section className="verification-section p-0">
      <Row className="m-0">
        <Col xl="6" md="10" xs="12" className="mx-auto p-0">
          <div className="verification-form">
            <div>
              <h3 className="title">Summarize Yourself</h3>
              <Form noValidate>
                <Row className="m-0">
                  <h5 className="form-title">General details</h5>
                  <Col lg={6}>
                    <Row className="g-xl-3 g-2">
                      <label className="input-label">Phone Number</label>
                      <Col xl={2} xs={3} className="m-0">
                        <FormGroup className="position-relative mb-lg-4 mb-3">
                          <input type="number" className="px-2 form-control text-center" placeholder="+91" />
                        </FormGroup>
                      </Col>
                      <Col xl={10} xs={9} className="m-0">
                        <FormGroup className="position-relative mb-lg-4 mb-3">
                          <input type="number" className="form-control px-3" placeholder="125889632" />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={6}>
                    <label className="input-label">Country</label>
                    <FormGroup className="position-relative mb-lg-4 mb-3">
                      <input type="text" className="form-control px-3" placeholder="india" />
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <label className="input-label">State</label>
                    <FormGroup className="position-relative mb-lg-4 mb-3">
                      <input type="text" className="form-control px-3" placeholder="Gujarat" />
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <label className="input-label">Gender</label>
                    <FormGroup className="position-relative form-control  mb-lg-4 mb-3 px-3">
                      <CommonSelect options={genderOptions} selectedOptions={genderSelected} setSelectedOptions={setGenderSelected} text="Gender" outer={false} />
                    </FormGroup>
                  </Col>

                  <h5 className="form-title">Category</h5>
                  <Col sm={6}>
                    <FormGroup className="position-relative form-control  mb-lg-4 mb-3 px-3">
                      <CommonSelect options={categoryOptions} selectedOptions={categorySelected} setSelectedOptions={setCategorySelected} text="Category" multipleSelect={true} outer={true} />
                    </FormGroup>
                  </Col>

                  <h5 className="form-title">Add your social channels</h5>
                  <Col sm={6}>
                    <label className="input-label">Instagram</label>
                    <FormGroup className="position-relative mb-lg-4 mb-3">
                      <input type="text" className="form-control px-3" placeholder="influencer_123" />
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <label className="input-label">YouTube</label>
                    <FormGroup className="position-relative mb-lg-4 mb-3">
                      <input type="text" className="form-control px-3" placeholder="Enter URL" />
                    </FormGroup>
                  </Col>
                </Row>
                <div className="action-btn pt-lg-3 pt-2">
                  <Btn className="btn-theme w-50 m-auto" type="submit">
                    Continue
                  </Btn>
                </div>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default SummarizeDetailsContainer;
