import { ArrowDropDown, ArrowDropUp, InfoOutlined, PlaylistAdd } from "@mui/icons-material";
import React, { Fragment, useEffect } from "react";
import { Table } from "reactstrap";

import { NoDataFound } from "./NoDataFound";
import CommonPagination from "../Pagination";
import { Btn, Image } from "../../../AbstractElement";
import { dynamicImage, formatInstagramNumber } from "../../../Helper";
import { useCreatorFavoriteContext } from "../../../Context/Creator/CreatorFavoriteContext";
import SortList from "./SortList";
import ExportCsvData from "./ExportCsvData";
import { Tooltip } from "@mui/material";

const getSortIcon = (column, showSortIcon, sortColumn, sortOrder) => {
  if (!showSortIcon) {
    return null;
  }
  if (sortColumn === column) {
    return sortOrder === "desc" ? <ArrowDropDown /> : <ArrowDropUp />;
  }
  return <ArrowDropUp style={{ opacity: 0.5 }} />;
};

export const createSortHeader = (className, label, showSortIcon, columnKey, sortColumn, sortOrder, handleSort, tooltip) => ({
  class: className,
  onClick: () => handleSort && handleSort(columnKey),
  render: () => (
    <span>
      {label}
      {tooltip && <InfoOutlined className="info-icon" />}
      {getSortIcon(columnKey, showSortIcon, sortColumn, sortOrder)}
    </span>
  ),
  tooltip: tooltip || "",
});

export const TableCommon = ({ isLoading, mainClass, tabClass, head, rows = [], tableCheck, pagination }) => {
  const { toggleTableRowAddModal, exportTableCsv } = useCreatorFavoriteContext();

  const handleSortList = (param, sort) => {
    tableCheck?.handleSort(param);
    tableCheck?.setSortOrder(sort);
  };

  useEffect(() => {
    if(isLoading) {
      document.body.classList.add('custom-bg-overscroll')
    } else {
      document.body.classList.remove('custom-bg-overscroll')
    }
  }, [isLoading]) 

  return (
    <Fragment>
      <div className="table-top-panel sb-space" style={{ position: "relative" }}>
        {isLoading && (
          <div className="loading-overlay">
            <div className="loading-message"></div>
          </div>
        )}
        {tableCheck?.selectedRows?.length ? (
          <div className="row-check">
            <h5 className="found-list order-sm-1 order-2">
              {tableCheck?.selectedRows?.length} creator{tableCheck?.selectedRows?.length > 1 && "s"} selected
            </h5>
            <div className="export-btn-box order-sm-2 order-1">
              <Btn onClick={toggleTableRowAddModal} className="more-filter-btn btn-pill-light">
                <PlaylistAdd /> Add to list
              </Btn>
              <Btn className="more-filter-btn btn-pill-light" onClick={() => exportTableCsv(tableCheck?.tableId)}>
                <Image className="img-fluid" src={dynamicImage("svg/export.svg")} /> Export CSV
              </Btn>
            </div>
          </div>
        ) : (
          <Fragment>
            {isLoading ? (
               <h5>Looking for the best matches for you...</h5>
             ) :
             <h5 className="found-list order-sm-1 order-2">{tableCheck?.total ? formatInstagramNumber(tableCheck?.total) : 0} Creators Found</h5>}
            
            <div className="list-btn-box order-sm-2 order-1">
              <SortList handleSortList={handleSortList} />
              <ExportCsvData params={tableCheck?.params} total={tableCheck?.total} tableId={tableCheck?.tableId} />
            </div>
          </Fragment>
        )}
      </div>
      <div className={`table-wrapper ${mainClass ? mainClass : ""}`}>
        {isLoading && (
          <div className="loading-overlay d-flex align-items-center justify-content-center gap-2 flex-column">
            <div class="custom-loader"></div>
            <div className="loading-message">Looking for the best matches for you...</div>
          </div>
        )}
        <div className={`table-responsive  scroll-bar ${isLoading ? "loading" : ""}`}>
          <Table className={tabClass}>
            {head && (
              <thead>
                <tr>
                  {head.map((item, headIndex) => (
                    <Fragment key={`head-${headIndex}`}>
                      {item.tooltip ? (
                        <Tooltip title={item.tooltip} placement="top">
                          <th className={`table-head ${item.class}`} onClick={rows.length > 0 ? item.onClick : null}>
                            {item.render()}
                          </th>
                        </Tooltip>
                      ) : (
                        <th className={`table-head ${item.class}`} onClick={rows.length > 0 ? item.onClick : null}>
                          {item.render()}
                        </th>
                      )}
                    </Fragment>
                  ))}
                </tr>
              </thead>
            )}
            {rows && (
              <tbody>
                {rows.length > 0 ? (
                  <Fragment>
                    {rows.map((row, rowIndex) => (
                      <tr key={`row-${rowIndex}`}>
                        {row.map((cell, cellIndex) => (
                          <td className={cell.class} key={`cell-${rowIndex}-${cellIndex}`}>
                            {cell.render()}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </Fragment>
                ) : (
                  <NoDataFound colSpan={head.length} />
                )}
              </tbody>
            )}
          </Table>
        </div>
      </div>
      <div style={{ position: "relative" }}>
        {isLoading && (
          <div className="loading-overlay">
            <div className="loading-message"></div>
          </div>
        )}
        {pagination && <CommonPagination limit={pagination.limit} selectOnChange={pagination.handleLimitChange} totalPages={pagination.totalPages} currentPage={pagination.currentPage} setCurrentPage={pagination.setCurrentPage} />}
      </div>
    </Fragment>
  );
};