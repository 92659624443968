import { ChevronLeft, ChevronRight, ExpandLess } from "@mui/icons-material";
import { MenuItem, Select } from "@mui/material";
import React from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

import { scrollToTop } from "../../../Helper";

const CommonPagination = ({ limit, selectOnChange, totalPages, currentPage, setCurrentPage }) => {
  const handlePageChange = (page) => {
    scrollToTop(220);
    setCurrentPage(page);
  };

  const handlePreviousButton = () => {
    if (currentPage !== 1) {
      handlePageChange(currentPage - 1);
    }
  };

  const handleNextButton = () => {
    if (currentPage !== totalPages) {
      handlePageChange(currentPage + 1);
    }
  };

  // const renderPageNumbers = () => {
  //   const pageNumbers = [];
  //   if (totalPages <= 6) {
  //      // If total pages are less than or equal to 6, render all page numbers
  //     for (let i = 1; i <= totalPages; i++) {
  //       pageNumbers.push(
  //         <PaginationItem key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
  //           <PaginationLink>{i}</PaginationLink>
  //         </PaginationItem>
  //       );
  //     }
  //   } else {
  //     // Always show the first page
  //     pageNumbers.push(
  //       <PaginationItem key="page-1" active={1 === currentPage} onClick={() => handlePageChange(1)}>
  //         <PaginationLink>1</PaginationLink>
  //       </PaginationItem>
  //     );

  //     // Add ellipsis if currentPage is greater than 3
  //     if (currentPage > 3) {
  //       pageNumbers.push(
  //         <PaginationItem key="start-ellipsis" disabled>
  //           <PaginationLink>...</PaginationLink>
  //         </PaginationItem>
  //       );
  //     }

  //      // Calculate startPage and endPage based on currentPage
  //     const startPage = Math.max(2, currentPage - 1);
  //     const endPage = Math.min(totalPages - 1, currentPage + 1);

  //      // Loop from startPage to endPage
  //     for (let i = startPage; i <= endPage; i++) {
  //       pageNumbers.push(
  //         <PaginationItem key={`page-${i}`} active={i === currentPage} onClick={() => handlePageChange(i)}>
  //           <PaginationLink>{i}</PaginationLink>
  //         </PaginationItem>
  //       );
  //     }

  //      // Add ellipsis if currentPage is not close to the last page
  //     if (currentPage < totalPages - 2) {
  //       pageNumbers.push(
  //         <PaginationItem key="end-ellipsis" disabled>
  //           <PaginationLink>...</PaginationLink>
  //         </PaginationItem>
  //       );
  //     }

  //      // Always show the last page
  //     pageNumbers.push(
  //       <PaginationItem key={`page-${totalPages}`} active={totalPages === currentPage} onClick={() => handlePageChange(totalPages)}>
  //         <PaginationLink>{totalPages}</PaginationLink>
  //       </PaginationItem>
  //     );
  //   }
  //   return pageNumbers;
  // };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    
    if (totalPages <= 6) {
      // If total pages are less than or equal to 6, render all page numbers
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(
          <PaginationItem key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
            <PaginationLink>{i}</PaginationLink>
          </PaginationItem>
        );
      }
    } else {
      // Always show the first page
      pageNumbers.push(
        <PaginationItem key="page-1" active={1 === currentPage} onClick={() => handlePageChange(1)}>
          <PaginationLink>1</PaginationLink>
        </PaginationItem>
      );
  
      // Add ellipsis if currentPage is greater than 3
      if (currentPage > 3) {
        pageNumbers.push(
          <PaginationItem key="start-ellipsis" disabled>
            <PaginationLink>...</PaginationLink>
          </PaginationItem>
        );
      }
  
      // Calculate startPage and endPage based on currentPage
      const startPage = currentPage > 3 ? currentPage : 2;
      const endPage = currentPage < totalPages - 2 ? currentPage + 1 : totalPages - 1;
  
      // Loop from startPage to endPage
      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(
          <PaginationItem key={`page-${i}`} active={i === currentPage} onClick={() => handlePageChange(i)}>
            <PaginationLink>{i}</PaginationLink>
          </PaginationItem>
        );
      }
  
      // Add ellipsis if currentPage is not close to the last page
      if (currentPage < totalPages - 2) {
        pageNumbers.push(
          <PaginationItem key="end-ellipsis" disabled>
            <PaginationLink>...</PaginationLink>
          </PaginationItem>
        );
      }
  
      // Always show the last page
      pageNumbers.push(
        <PaginationItem key={`page-${totalPages}`} active={totalPages === currentPage} onClick={() => handlePageChange(totalPages)}>
          <PaginationLink>{totalPages}</PaginationLink>
        </PaginationItem>
      );
    }
  
    return pageNumbers;
  };
  

  return (
    <div className="st-space pagination-panel">
      <div className="pagination-select-wrap">
        <span>Shows :</span>
        <Select value={limit} onChange={selectOnChange} IconComponent={ExpandLess}>
          <MenuItem value={25}>25</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={75}>75</MenuItem>
        </Select>
      </div>
      <Pagination>
        <PaginationItem onClick={handlePreviousButton} disabled={currentPage === 1}>
          <PaginationLink className="prev">
            <ChevronLeft fontSize="small" />
          </PaginationLink>
        </PaginationItem>
        {renderPageNumbers()}
        <PaginationItem onClick={handleNextButton} disabled={currentPage === totalPages}>
          <PaginationLink className="next">
            <ChevronRight fontSize="small" />
          </PaginationLink>
        </PaginationItem>
      </Pagination>
    </div>
  );
};

export default CommonPagination;
