import React from "react";
import { Col, Container, Row } from "reactstrap";

import { dynamicImage } from "../../Helper";
import { Image } from "../../AbstractElement";
import { Link } from "react-router-dom";
import CustomAccordion from "../../CommonElement/Accordion";
import AccordionContent from "../../CommonElement/Accordion/AccordionContent";

const FAQSection = () => {
  const faqData = [
    {
      title: "How do I get started?",
      description: "Getting started is simple! Sign up for a free account, and begin exploring creators. You can apply advanced filters and even try our AI-powered recommendation tool (coming soon) to find influencers who align with your goals."
    },
    {
      title: "Can I filter influencers based on specific criteria?",
      description: "Yes, we offer a variety of filters to help you find the right creators. You can search based on advanced filters like follower count, hashtags, keywords, engagement rate, and more. Our filtering system is designed to make it easy for you to discover influencers that match your brand’s unique needs."
    },
    {
      title: "How will Fyndor’s AI recommendation model work?",
      description: "Our AI recommendation model analyzes various factors such as engagement rates, advanced text analysis, and niche to match brands with the most suitable influencers. This will soon be live on the platform."
    },
    {
      title: "Is Fyndor suitable for both small and large brands & agencies?",
      description: "Absolutely! Whether you’re looking to collaborate with micro-influencers or targeting high-reach creators, we have options for everyone. Our platform is designed to cater to businesses of all sizes, offering flexibility in influencer partnerships."
    }
  ];

  return (
    <section className="faq-section lb-space">
      <Container>
        <Row className="g-lg-5 g-4">
          <Col lg={6} className="d-lg-block d-none">
            <Image className="img-fluid" src={dynamicImage("banner/faq.png")} alt="icon" />
          </Col>
          <Col lg={6} className="custom-faq-accordion">
            <h3 className="title">FAQs</h3>
            <p className="sub-title sb-space">If you have additional queries, feel free to reach us <Link to="https://abc2wqbypsj.typeform.com/to/ockRyqSM" target="_blank"> here.</Link></p>
            <CustomAccordion>
              {faqData.map((item, index) => (
                <AccordionContent key={index} title={item.title}>
                  {item.description}
                </AccordionContent>
              ))}
            </CustomAccordion>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default FAQSection;
