import { Toaster } from "react-hot-toast";

// dynamic image
const images = require.context(`/public/assets/images`, true);

// dynamic image
const svgImage = require.context(`/public/assets/images/svg`, true);

export const dynamicImage = (image) => {
  return images(`./${image}`);
};


export const dynamicSvg = (image) => {
  return svgImage(`./${image}`);
};


// CustomToaster
export const CustomToaster = () => (
  <Toaster
    toastOptions={{
      duration: 3000,
      position: "top-right",
      style: {
        fontWeight: 700,
        minWidth: 200,
      },
      success: {
        style: {
          background: "rgb(229, 245, 227)",
          color: "rgba(var(--success), 1)",
        },
      },
      error: {
        style: {
          background: "rgb(255, 238, 237)",
          color: "rgba(var(--error), 1)",
        },
      },
    }}
  />
);

// Format Instagram Number with M/K
export const formatInstagramNumber = (num) => {
  if (num >= 1000000) {
    const formattedNum = (num / 1000000).toFixed(2);
    return formattedNum.endsWith(".00") ? `${parseInt(formattedNum)}M` : `${formattedNum}M`;
  } else if (num >= 1000) {
    const formattedNum = (num / 1000).toFixed(2);
    return formattedNum.endsWith(".00") ? `${parseInt(formattedNum)}K` : `${formattedNum}K`;
  }
  return num;
};

// Format Instagram Number with Million/Thousand
export const formatInstagramNumberFull = (num) => {
  if (num >= 1000000) {
    const formattedNum = (num / 1000000).toFixed(2);
    return formattedNum.endsWith(".00") ? `${parseInt(formattedNum)} Million` : `${formattedNum} Million`;
  } else if (num >= 1000) {
    const formattedNum = (num / 1000).toFixed(2);
    return formattedNum.endsWith(".00") ? `${parseInt(formattedNum)} Thousand` : `${formattedNum} Thousand`;
  }
  return num;
};

export const parseInstagramNumber = (str) => {
  if (typeof str !== "string") return NaN;

  // Remove any non-numeric characters and convert to number
  const numberPart = parseFloat(str.replace(/[^0-9.]/g, ""));

  // Determine the multiplier based on suffix
  if (str.includes("M")) {
    return numberPart * 1000000;
  } else if (str.includes("K")) {
    return numberPart * 1000;
  }

  return numberPart;
};

// Format Percentage
export const formatPercentage = (value) => {
  if (value === 0 || value === null) {
    return "0%";
  }
  if (value === 100) {
    return "100%";
  }
  return `${parseFloat(value.toFixed(2))}%`;
};

// ScrollToTop
export const scrollToTop = (scroll = 0) => {
  window.scrollTo({
    top: scroll,
    behavior: "smooth",
  });
};

// Delay
export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// Helper function to parse range values from a string
export const parseRange = (param) => {
  if (typeof param === "string" && param.includes("-")) {
    const [min, max] = param.split("-").map(Number);
    return {
      min: isNaN(min) ? null : min,
      max: isNaN(max) ? null : max,
    };
  }
  return { min: null, max: null };
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);

  // Get day, month, and year
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();

  // Array of month names
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const month = monthNames[date.getUTCMonth()];

  // Return formatted string
  return `${day.toString().padStart(2, "0")} ${month}, ${year}`;
};

// export const Data = ({ security }) => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   // Extract authentication paths from AuthRoutes
//   const authPaths = AuthRoutes.map((route) => route.path);
//   // Combine both ProtectedRoutes and ProtectedProfileRoutes
//   const securePaths = [...(ProtectedRoutes || []), ...(ProtectedProfileRoutes || []), ...(AdminRoutes || [])].map((route) => route.path);
//   useEffect(() => {
//     const isAuthPath = authPaths.includes(location.pathname);
//     const isSecurePath = securePaths.includes(location.pathname);
//     if (security) {
//       if (!isAuthPath && !isSecurePath) {
//         toast.error("Page Not Found !!!");
//         navigate(ROUTES.Home);
//       } else if (isAuthPath) {
//         toast.success("User Login Already");
//         navigate(ROUTES.Home);
//       } else if (isSecurePath) {
//         toast.error("Access Denied Login First");
//         navigate(ROUTES.Auth.Login);
//       }
//     }
//   }, [authPaths, location.pathname, navigate, securePaths, security]);
//   return null;
// }

export const checkActiveRang = (rang) => {
  // Check if both "from" and "to" are null, return false (inactive)
  if (rang.from === null && rang.to === null) {
    return false;
  }

  // If either "from" or "to" is not null, return true (active)
  return rang.from !== null || rang.to !== null;
};
