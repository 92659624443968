import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";

import { dynamicImage } from "../../Helper";
import { Image } from "../../AbstractElement";
import { categoriesSliderData, categoriesSliderDataBreakPoint } from "../../Data/HomeData";

const AiPoweredSection = () => {
  return (
    <section className="categories-section">
      <div className="home-btns">
        <p className="mb-2">Coming soon</p> 
      </div>
      <h3 className="title text-center">New AI-powered Recommendation Engine</h3>
      <p className="sub-title text-center sb-space">Find influencer's who perfectly match your brand's niche.</p>
      <Swiper slidesPerView={4} loop={true} spaceBetween={-18} modules={[Navigation, Autoplay]} navigation={true} autoplay={{ delay: 2000, disableOnInteraction: false }} speed={1500} centeredSlides={true} breakpoints={categoriesSliderDataBreakPoint} className="banner1-slider arrow-style-1 arrow-style-2">
        {categoriesSliderData.map((item) => (
          <SwiperSlide key={item.id}>
            <div className="banner-part"> 
                <Image className="img-fluid" src={dynamicImage(`categories/${item.title}`)} />
                <h5 className="categories-title">{item.text}</h5>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default AiPoweredSection;
