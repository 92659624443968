import React, { useEffect, useState } from "react";
import { Row, Col, Form, FormGroup } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";

import OTPInput from "../Common/OTPInput";
import { dynamicImage } from "../../../Helper";
import { ROUTES } from "../../../Routes/Routes";
import { Btn, Image } from "../../../AbstractElement";
import getStorage, { KEYS } from "../../../Helper/getStorage";
import { useAuthContext } from "../../../Context/Auth/AuthContext";
import useApiState from "../../../Hooks/useApiState";

const EmailVerifyContainer = () => {
  const navigate = useNavigate();
  const { getItem } = getStorage();
  const [timer, setTimer] = useState(60);
  const [val, setVal] = useState(Array(6).fill(""));
  const [isError, setIsError] = useState(false);
  const [isResendEnabled, setIsResendEnabled] = useState(false);
  const { handleEmailVerification, handleReset } = useAuthContext();
  const { loading, setLoading } = useApiState()

  const getEmail = JSON.parse(getItem(KEYS.EMAIL));
  const isSubmitDisabled = val.some((value) => value === "" || value === undefined);

  useEffect(() => {
    if (timer > 0) {
      const countdown = setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
      return () => clearTimeout(countdown);
    } else {
      setIsResendEnabled(true);
    }
  }, [timer]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const otpCode = val.join("");
    handleEmailVerification({ getEmail, otpCode, navigate, setIsError, setLoading });
  };

  const handleResend = () => {
    setTimer(60);
    setIsResendEnabled(false);
    handleReset({ getEmail });
  };

  return (
    <section className="verification-section py-0">
      <Row className="m-0 h-100">
        <Col xxl="6" lg="7" md="8" xs="12" className="mx-auto p-0">
          <div className="verification-form">
            <div className="px-3">
              <Image className="verify-gif" src={dynamicImage("gif/verify_mail.gif")} />
              <div className="title">
                <h3>Verify Your Email Address</h3>
                <p>
                  We have sent a verification Code to <span>{getEmail}</span>
                </p>
                <p className="code-text">Please enter Code to verify your email address.</p>
              </div>
              <Form noValidate onSubmit={handleSubmit}>
                <div className="otp-code">
                  <FormGroup>
                    <OTPInput val={val} setVal={setVal} isError={isError} setIsError={setIsError} />
                  </FormGroup>
                </div>
                <div className="resend-code">
                  <div>
                    <p className="sec">00 : {String(timer).padStart(2, "0")}</p>
                  </div>
                  <span className="resend">
                    Didn’t receive a code?
                    <Btn disabled={!isResendEnabled} className="p-0" onClick={handleResend}>
                      Resend
                    </Btn>
                  </span>
                </div>
                <div className="action-btn">
                  <Btn block type="submit" disabled={isSubmitDisabled || loading} className="btn-theme" loading={loading}>
                    Verify
                  </Btn>
                  <Link to={ROUTES.Auth.Login} className="btn w-100 mt-sm-3 mt-2">
                    <Image className="back-arrow" src={dynamicImage("svg/arrow-left.svg")} />
                    Back
                  </Link>
                </div>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default EmailVerifyContainer;
