import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";

import { API } from "../../../Api/ApiList";
import { getParams } from "../../../Api/UrlParams";
import { useGetApi } from "../../../Api/useGetApi";
import { useCreatorContext } from "../../../Context/Creator/CreatorContext";
import { useCreatorFavoriteContext } from "../../../Context/Creator/CreatorFavoriteContext";
import useApiState from "../../../Hooks/useApiState";
import { TableSkeleton } from "../../../Layout/Loader/SkeltonLoaders";
import { DefaultContent } from "../../Common/Table/DefaultContent";
import { ContactOptionModal } from "../Modals/ContactOptionModal";
import { CreateNewListModal } from "../Modals/CreateNewListModal";
import { CreatorListOffcanvas } from "../Modals/CreatorListOffcanvas";
import { DeleteListModal } from "../Modals/DeleteListModal";
import FilterOffcanvas from "../Modals/FilterOffcanvas";
import { TableRowAddModal } from "../Modals/TableRowAddModal";
import { CreatorsFilters } from "./CreatorFilters";
import { CreatorTable } from "./RenderTable/CreatorTable";

const SearchByFilters = ({ topicIsLoading, tagIsLoading, categoryIsLoading }) => {
  const getParam = getParams();
  const [aiResult, setAiResult] = useState(false);
  const [urlParamsProcessed, setUrlParamsProcessed] = useState(false);
  const { filterCanvas, contactOptionModal } = useCreatorContext();
  const [showBrandNotFoundMessage, setShowBrandNotFoundMessage] = useState(false);
  const { setCreatorData, tableRowAddModal, creatorListModal, listAddModal, deleteListModal } = useCreatorFavoriteContext();
  const { handleSort, page, setPage, total, setTotal, limit, setLimit, totalPages, setTotalPages, params, setParams, sortOrder, sortColumn, setSortOrder, handleLimitChange, dataFetched, setDataFetched, setDefaultSort } = useApiState();
   
  const { data, refetch, isLoading } = useGetApi({
    url: API.creator.findCreatorApi,
    params: { ...params, page, limit, sortColumn: sortColumn, sortOrder :sortOrder  },
    options: { enabled: false, refetchOnWindowFocus: false },
  });

  useEffect(() => {
    if (data) {
      setDataFetched(true);
      setShowBrandNotFoundMessage(true);
      setCreatorData(data?.data);
      setTotalPages(data?.totalPages);
      setTotal(data?.total);
      setAiResult(data?.ai);
    }
  }, [data, setCreatorData, setDataFetched, setTotal, setTotalPages]);

  const getAndSetParams = useCallback(() => {
    const { channelTopic, videoLanguage, videoCategory, videoDurations, postKeyWord, tag, creatorLocations, category, accountTypes, platform, creatorMinContent, creatorMaxContent, brand, hashtags, creatorMinFollower, creatorMaxFollower, creatorMinLike, creatorMaxLike, creatorMinComment, creatorMaxComment, creatorMinView, creatorMaxView, engagementRate, isVerify, mostRecentPosts, mentions, biokeyword } = getParams();

    setParams((prevParams) => ({
      ...prevParams,
      platform: `%` + platform + `%` || null,
      brand: brand || null,
      hashtags: hashtags?.replace("#", "").split(",").join(","),
      min_follower_count: creatorMinFollower !== null ? creatorMinFollower : null,
      max_follower_count: creatorMaxFollower !== null ? creatorMaxFollower : null,
      engagement_rate: engagementRate || null,
      is_verified: isVerify === "Yes" ? 1 : isVerify === "No" ? 0 : null,
      min_no_of_content: creatorMinContent !== null ? creatorMinContent : null,
      max_no_of_content: creatorMaxContent !== null ? creatorMaxContent : null,
      recent_post: mostRecentPosts || null,
      mentions: mentions?.replace("@", "").split(",").join(","),
      bio_keyword: biokeyword || null,
      min_avg_likes: creatorMinLike !== null ? creatorMinLike : null,
      max_avg_likes: creatorMaxLike !== null ? creatorMaxLike : null,
      min_avg_comment: creatorMinComment !== null ? creatorMinComment : null,
      max_avg_comment: creatorMaxComment !== null ? creatorMaxComment : null,
      min_avg_views: creatorMinView !== null ? creatorMinView : null,
      max_avg_views: creatorMaxView !== null ? creatorMaxView : null,
      account_type: accountTypes || null,
      categories: category || null,
      tags: tag || null,
      creator_location: creatorLocations || null,
      post_keyword: postKeyWord || null,
      video_category: videoCategory || null,
      duration: videoDurations || null,
      video_language: videoLanguage || null,
      topics: channelTopic || null,
    }));
  }, [setParams]);

  useEffect(() => {
    setUrlParamsProcessed(true);
    if (urlParamsProcessed && Object.keys(params).some((key) => params[key] !== null)) {
      refetch();
    }
  }, [params, page, limit, sortOrder, sortColumn, urlParamsProcessed, refetch]);

  useEffect(() => {
    const getParam = getParams();
    if (Object.keys(getParam).some((key) => getParam[key] !== null)) {
      setUrlParamsProcessed(true);
      getAndSetParams();
    }
  }, [getAndSetParams]);

  const renderTableContent = () => {
    if (!dataFetched && !isLoading) {
      return <DefaultContent image="no-filter" title="No filters added yet." subTitle="Apply filters to narrow down your search and get more accurate results!" />;
    }

    return <CreatorTable isLoading={isLoading} params={params} sortColumn={sortColumn} sortOrder={sortOrder} setSortOrder={setSortOrder} handleSort={handleSort} limit={limit} handleLimitChange={handleLimitChange} totalPages={totalPages} page={page} setPage={setPage} total={total} />;
  };

  return (
    <Fragment>
      <Row>
        <Col sm="12">
          <CreatorsFilters topicIsLoading={topicIsLoading} categoryIsLoading={categoryIsLoading} loading={isLoading} params={params} setParams={setParams} setUrlParamsProcessed={setUrlParamsProcessed} getAndSetParams={getAndSetParams} setPage={setPage} setLimit={setLimit} setDataFetched={setDataFetched} setDefaultSort={setDefaultSort} />
          {getParam.brand ? aiResult ? renderTableContent() : isLoading ? <TableSkeleton loop={7} /> : showBrandNotFoundMessage && <DefaultContent imgClass="ai-img" image="ai-search" title="Unable to locate your brand." subTitle="Please contact us, and we'll assist you!" /> : renderTableContent()}
        </Col>
      </Row>
      {filterCanvas && <FilterOffcanvas tagIsLoading={tagIsLoading} topicIsLoading={topicIsLoading} categoryIsLoading={categoryIsLoading} getAndSetParams={getAndSetParams} setUrlParamsProcessed={setUrlParamsProcessed} setDefaultSort={setDefaultSort} isLoading={isLoading} />}
      {creatorListModal && <CreatorListOffcanvas />}
      {listAddModal && <CreateNewListModal />}
      {deleteListModal && <DeleteListModal />}
      {contactOptionModal && <ContactOptionModal />}
      {tableRowAddModal && <TableRowAddModal />}
    </Fragment>
  );
};

export default SearchByFilters;
