import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { ROUTES } from "../../../Routes/Routes";
import { Image } from "../../../AbstractElement";
import { dynamicImage } from "../../../Helper";
import AdminLoginForm from "./AdminLoginForm";

export const AdminLoginContainer = () => {
  return (
    <section className="auth-section admin-login">
      <Row className="auth-main g-0 justify-content-center">
        <Col lg={6}>
          <div className="auth-form">
            <div>
              <Link to={ROUTES.Home}>
                <Image className="logo" src={dynamicImage("logo.svg")} />
              </Link>
              <h2>Welcome!</h2>
              <h6>Please Log In to Your Admin Account</h6>
              <AdminLoginForm />
            </div>
          </div>
        </Col>
      </Row>
    </section>
  );
};
