export const instagramApi = "api/auth/instagram";
export const instagramCallbackApi = "api/auth/instagram/callback";

export const API = {
  admin:{
    adminLoginApi: "api/admin/login",
    loginActivity: "api/admin/loginActivity",
    statics:"api/admin/statics",
    brandList:"api/admin/brandUsers"
  },
  auth: {
    registerApi: "api/auth/register",
    otpVerifyApi: "api/auth/otpVerify",
    resendOtpApi: "api/auth/resendOtp",
    loginApi: "api/auth/login",
    forgotPasswordApi: "api/auth/forgotpassword",
    verifyEmailApi: "api/auth/verifyEmail",
    resetPasswordApi: "api/auth/resetpassword",
  },
  creator: {
    findCreatorApi: "api/findCreators",
    getInfluencerFavoriteListApi: "api/favorite-lists",
    addInfluencerFavoriteListApi: "api/favorite-lists",
    deleteInfluencerFavoriteListApi: "api/favorite-lists",
    addInfluencerToFavoriteListApi: "api/favorite-lists/influencers",
    deleteInfluencerFromListApi: "api/favorite-lists/influencers",
    getCreatorVideoLanguageApi: "api/langList",
    getCreatorChannelTopicsApi: "api/topicList",
    getCreatorTagApi: "api/tagList",
    getCreatorCategoryApi: "api/categoryList",
  },
};
