import toast from "react-hot-toast";
import React from "react";
import { Form, Modal, ModalBody, ModalHeader } from "reactstrap";
import { TextField } from "@mui/material";

import { Btn, Image } from "../../../AbstractElement";
import { useCreatorFavoriteContext } from "../../../Context/Creator/CreatorFavoriteContext";
import { dynamicImage } from "../../../Helper";
import { useAutoFocus } from "../../../Hooks/useAutoFocus";
import { usePostApi } from "../../../Api/usePostApi";
import { API } from "../../../Api/ApiList";

export const CreateNewListModal = () => {
  const { listAddModal, toggleListAddModal, listName, setListName } = useCreatorFavoriteContext();
  const inputRef = useAutoFocus(listAddModal);

  // Create new list
  const { mutate, isPending } = usePostApi({
    url: API.creator.addInfluencerFavoriteListApi,
    data: { name: listName },
    onSuccess: () => {
      toast.success("List created successfully");
      setListName("");
      toggleListAddModal();
    },
  });

  const handleCreateList = (e) => {
    e.preventDefault();
    mutate();
  };

  return (
    <Modal className="creator-addlist" centered isOpen={listAddModal} toggle={toggleListAddModal} onExit={() => setListName("")}>
      <ModalHeader toggle={toggleListAddModal} className="border-0"></ModalHeader>
      <Form onSubmit={handleCreateList}>
        <ModalBody>
          <div>
            <div className="list-wrap">
              <div className="list-icon"><Image src={dynamicImage("svg/add-list.svg")} alt="add-list Icon" /></div>
              <div className="list-detail">
                <h5>Create new list</h5>
                <p>Lists you create will be visible in campaigns</p>
              </div>
            </div>
            <TextField inputRef={inputRef} className="w-100" label="List name" value={listName} onChange={(e) => setListName(e.target.value)} variant="outlined" placeholder="Enter label list name ..." />
            <Btn block className="btn-theme mt-3" disabled={isPending || listName.trim() === ""} loading={isPending}>Create</Btn>
          </div>
        </ModalBody>
      </Form>
    </Modal>
  );
};
