import React, { useState } from 'react';
import { Accordion, AccordionItem, AccordionHeader, AccordionBody } from 'reactstrap';

// Higher-Order Component for Accordion
const CustomAccordion = ({ children }) => {
    const [open, setOpenIndex] = useState(null);

    const toggle = (index) => {     
        setOpenIndex(open === index ? null : index);
    };
  
    return (
        <Accordion toggle={toggle} ac>
            {React.Children.map(children, (child, index) => (
                <AccordionItem key={index}>
                    <AccordionHeader onClick={() => toggle(index)}>
                        {child.props.title}
                    </AccordionHeader>
                    {open === index && (
                        <AccordionBody isOpen={open === index}>
                            {child}
                        </AccordionBody>
                    )}
                </AccordionItem>
            ))}
        </Accordion>
    );
};

export default CustomAccordion;
