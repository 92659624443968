import React, { useState } from "react";

import { dynamicImage } from "../../Helper";
// import { ROUTES } from "../../Routes/Routes";
// import { useNavigate } from "react-router-dom";
import { Image } from "../../AbstractElement";

const CreatorCard = ({ item, data }) => {
  // const navigate = useNavigate();
  const [likeStatus, setLikeStatus] = useState(
    data.reduce((acc, item) => {
      acc[item.id] = false;
      return acc;
    }, {})
  );

  const toggleLike = (id) => {
    setLikeStatus((prevStatus) => ({
      ...prevStatus,
      [id]: !prevStatus[id],
    }));
  };

  const handleView = (id) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    // navigate(`${ROUTES.Pages.Creator}/${id}`);
  };

  return (
    <div className="creators-box">
      <div className="creators-img-box">
        {/* <Btn className="btn-theme">Collaborate Now</Btn> */}
        {item.creator && (
          <label>
            <Image className="img-fluid" src={dynamicImage("svg/flash.svg")} alt="icon" />
            {item.creator}
          </label>
        )}
        {/* <div className="like-btn" onClick={() => toggleLike(item.id)}>
          <Image className="img-fluid dislike" src={dynamicImage(`svg/heart-${likeStatus[item.id] ? "fill" : "line"}.svg`)} alt="dislike" />
        </div> */}
        <Image className="img-fluid influencers-img" src={dynamicImage(`influencers/${item.title}.jpg`)} alt={item.title} />
      </div>
      <div className="creators-details">
        <div className="top-panel">
          <h5 onClick={() => handleView(item.id)}>{item.name}</h5>
          <h6>{item.category}</h6>
        </div>
        <p>{item.text}</p>
        <div className="bottom-panel">
          <div className="user-location">
            <Image className="img-fluid" src={dynamicImage("svg/location.svg")} alt="location" />
            <span>{item.location}</span>
          </div>
          {item.rate && <h5 className="price">${item.rate}</h5>}
        </div>
      </div>
    </div>
  );
};

export default CreatorCard;
